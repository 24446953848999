import dayjs from "dayjs";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCheckMark } from "elevar-design-system/src/icons";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import {
  useDestinationOAuthConnectMutation,
  useDestinationOAuthDisconnectMutation
} from "../../api/handlers/website";
import { ActionWarningModal } from "../../components/ActionWarningModal";
import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { formatTitle } from "../../utils/format";
import { toast } from "../../utils/toast";
import { ConsentMode } from "./ConsentMode";
import { destinationKlvo as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";

/* ========================================================================== */

const setupGuideHref = "https://docs.getelevar.com/docs/klaviyo";

type SetupFlowContext = { shopifyOAuthScopes: ShopifyOAuthScopes };

const {
  SetupFlow,
  useConfigRequired,
  useSetupFlowDetails,
  useSetupFlowContext
} = createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationKlvoProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationKlvo: React.FC<DestinationKlvoProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      stepInfo={[
        { name: "Klaviyo Settings" },
        { name: "Events" },
        { name: "Consent Mode" },
        { name: "Filter Transactions" },
        { name: "Subscriptions" }
      ]}
      context={{ shopifyOAuthScopes }}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
  }
};

/* ========================================================================== */

type Events = ReturnType<typeof useConfigRequired>["enabledEvents"];

const recommendedEvents: Events = {
  viewItemList: true,
  addToCart: true,
  viewItem: true,
  pageView: false,
  purchase: false,
  beginCheckout: false
};

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              Klaviyo account <span>connected</span>
            </>
          )
        },
        {
          step: 2,
          type: "EVENTS",
          selectedEvents: {
            enabledEvents: config.enabledEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          },
          recommendedEvents: getRecommendedEventState({
            destination,
            enabledEvents: recommendedEvents,
            enabledWebEvents: {},
            webhookOverrides: config.webhookOverrides
          })
        },
        {
          step: 3,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 4,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 5,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          Set up this server-side tracking integration to:
          <ul>
            <li>Improve Abandonment Flow performance</li>
            <li>
              Identify abandoners that your client-side tracking misses due to
              cookie limitations
            </li>
          </ul>
          <StyledLinkExternal
            href="https://www.getelevar.com/how-to/improve-klaviyo-abandoned-flow-performance/"
            text="Learn more"
          />
        </Step0Explainer>
      }
      whatsNextOverrides={{
        title: "Set Up Your Split Test",
        explainer: (
          <>
            Send messages to only the net new abandoners identified by Elevar so
            that you can see the net new revenue generated.
          </>
        ),
        media: {
          type: "VIDEO",
          url: "https://player.vimeo.com/video/906116823"
        },
        link: {
          href: "https://docs.getelevar.com/docs/how-to-analyze-klaviyo-server-side-performance-setup-abandoned-flows",
          text: "How to Setup Klaviyo Split Test Flows"
        }
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > ul {
    margin-top: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 2}px;
    padding-left: ${props => props.theme.gridBase * 2}px;

    > li {
      list-style: disc;

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 0.25}px;
      }
    }
  }
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const { mutateAsync: destinationOAuthConnectMutation } =
    useDestinationOAuthConnectMutation({ target: destination.configKey });
  const { mutateAsync: destinationOAuthDisconnectMutation } =
    useDestinationOAuthDisconnectMutation({ target: destination.configKey });

  const [isModalShown, setIsModalShown] = useState(false);

  const isConnected = config.accessToken !== "";
  const authDate = config.last_authed_at ? dayjs(config.last_authed_at) : null;

  return (
    <>
      <Step1Wrapper>
        <PageCard>
          <StepSection
            title="Klaviyo Settings"
            setupGuideHref={setupGuideHref}
            description={
              <Step1Explainer>
                To start sending your e-commerce funnel data to Klaviyo, please
                connect your account.
              </Step1Explainer>
            }
          >
            {isConnected ? (
              <Step1ConnectedWrapper>
                <div>
                  <div>
                    <div>
                      <IconCheckMark size="24px" />
                    </div>
                    <div>Account connected</div>
                  </div>
                  {authDate ? (
                    <div>
                      <div>Last updated:</div>
                      <div>{authDate.format("MMM DD YYYY")}</div>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button onClick={() => setIsModalShown(true)}>
                    Disconnect
                  </button>
                </div>
              </Step1ConnectedWrapper>
            ) : (
              <Step1ConnectButton
                variant="SMALL"
                state={setupFlow.isLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  setupFlow.setIsLoading(true);

                  try {
                    const result = await destinationOAuthConnectMutation();
                    window.location.replace(result.redirect_url);
                  } catch (error) {
                    setupFlow.setIsLoading(false);
                    toast.errorUnexpected(error);
                  }
                }}
              >
                <destination.icon size="16px" color={theme.palette.white} />
                <div>Connect Klaviyo Account</div>
              </Step1ConnectButton>
            )}
          </StepSection>
          {isConnected ? (
            <MarketGroupSettings
              config={config}
              destination={destination}
              isLoading={setupFlow.isLoading}
              isStepCompleted={setupFlow.isStepCompleted}
              onSave={data => setupFlow.configMutation(data)}
            />
          ) : null}
        </PageCard>
      </Step1Wrapper>
      {isConnected ? (
        <ActionWarningModal
          isVisible={isModalShown}
          onClose={() => setIsModalShown(false)}
          isLoading={setupFlow.isLoading}
          subheading={formatTitle(destination.name, config.label)}
          heading="Disconnect Klaviyo Account"
          text="By disconnecting your account, you're accepting that:"
          checkBoxItems={[
            "Your integration will be automatically set to offline",
            "Your integration will stop receiving conversions data",
            "Your conversion data accuracy will be interrupted"
          ]}
          confirmActionText="Disconnect"
          onConfirmAction={async () => {
            setupFlow.setIsLoading(true);
            await destinationOAuthDisconnectMutation();
            await setupFlow.configMutation({ live: false, completedStep: 0 });
            setupFlow.setIsLoading(false);
            toast.success("Klaviyo disconnected");
          }}
          cancelActionText="Go Back"
        />
      ) : null}
    </>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1ConnectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > div:first-child {
    > div:first-child {
      display: flex;
      gap: ${props => props.theme.gridBase}px;

      > div:first-child {
        color: ${props => props.theme.palette.green};
      }

      > div:last-child {
        ${normalBodyStyles};
        font-weight: 500;
      }
    }

    > div:not(:first-child) {
      ${smallTextStyles};
      display: flex;
      gap: ${props => props.theme.gridBase}px;
      margin-top: ${props => props.theme.gridBase * 1.5}px;

      > div:first-child {
        color: ${props => props.theme.palette.grey2};
      }

      > div:last-child {
        color: ${props => props.theme.palette.grey1};
      }
    }
  }

  > div:last-child {
    > button {
      ${normalBodyStyles};
      ${linkStyles};
    }
  }
`;

const Step1ConnectButton = styled(ButtonPrimary)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();
  const { shopifyOAuthScopes } = useSetupFlowContext();

  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });
  const [eventMapping, setEventMapping] = useState(config.eventMapping);

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Klaviyo.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={setupFlow.isLoading}
        isStepCompleted={setupFlow.isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        showBreakdownByDefault={true}
        details={{
          eventState,
          setEventState,
          destinationName: destination.name,
          eventMapping,
          setEventMapping
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={async () => {
          await setupFlow.configMutation({ ...eventState, eventMapping });
        }}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={orderFilters => setupFlow.configMutation({ orderFilters })}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: data => setupFlow.configMutation(data)
      }}
    />
  );
};
