import { capitalize, lowerCase } from "lodash-es";
import { transparentize } from "polished";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";
import { type EmptyObject, type FixedLengthArray } from "type-fest";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";
import { type OptionalPromise } from "elevar-common-ts/src/utils";

import { ButtonDropdown } from "elevar-design-system/src/buttons/ButtonDropdown";
import { iconButtonStyles } from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconBolt,
  IconCheckMark,
  IconCircledInfo,
  IconCircledPause,
  IconCoffee,
  IconDotsHorizontal,
  IconHouse,
  IconRefresh
} from "elevar-design-system/src/icons";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";
import { useUpdateLayoutEffect } from "elevar-design-system/src/useUpdateEffect";

import {
  type EventsConnectorConfigCompletedStep,
  type SingularEventsConnectorConfig,
  useEventsConnectorConfigMutation
} from "../api/handlers/website";
import { BackLink } from "../components/BackLink";
import { ContactCallout } from "../components/ContactCallout";
import { InputFieldDestinationName } from "../components/InputFieldDestinationName";
import { Modal } from "../components/Modal";
import { PageCard } from "../components/PageCard";
import { Status } from "../components/Status";
import {
  type Destination,
  type Source,
  sourceCustomPages
} from "../routes/myTracking/data";
import { emptyStringCleaveValues } from "../utils/cleave";
import { formatTitle } from "../utils/format";
import { useCompanyId, useConfigId, useWebsiteId } from "../utils/idHooks";
import { toast } from "../utils/toast";
import { track } from "../utils/track";
import { useMyTrackingDetails } from "./MyTrackingDetails";
import { usePageScrollContainer } from "./PageScrollContainer";

/* ========================================================================== */

const getValidCompletedStep = (stepCount: number, apiStep: number | null) => {
  if (apiStep === null) return apiStep;
  if (apiStep >= 0 && apiStep <= stepCount) return apiStep;
  else return null;
};

const getInitialCurrentStep = (stepCount: number, apiStep: number | null) => {
  if (apiStep === null) return 0;
  if (apiStep === stepCount) return 0;
  else return apiStep + 1;
};

type StepInfo<T extends Source | Destination> = FixedLengthArray<
  { name: string; enforceOnUpgradeOrUpdate?: boolean; isVisible?: boolean },
  T["stepCount"]
>;

type BaseNavigationStep = {
  text: string;
  isVisible: boolean;
  isRequiredOnUpgradeOrUpdate: boolean;
  onClick: () => void;
};

type ConfigMutationOptions = {
  disableAutoLoading?: true;
  disableAutoStepComplete?: true;
};

type UseSetupFlowDetailsInternalBaseArgs<T extends Source | Destination> = {
  data: T;
  stepInfo: StepInfo<T>;
  completedStep: EventsConnectorConfigCompletedStep<T>;
};

const useSetupFlowDetailsInternalBase = <T extends Source | Destination>({
  data,
  stepInfo,
  completedStep
}: UseSetupFlowDetailsInternalBaseArgs<T>) => {
  const history = useHistory();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const pageScrollContainer = usePageScrollContainer();

  const { mutateAsync: configMutation } =
    useEventsConnectorConfigMutation(data);

  const [currentStep, setCurrentStep] = useState(
    getInitialCurrentStep(data.stepCount, completedStep)
  );

  type CurrentStep = NonNullable<typeof completedStep>;

  const steps: Array<BaseNavigationStep> = stepInfo.map((step, index) => ({
    text: step.name,
    isVisible: step.isVisible ?? true,
    isRequiredOnUpgradeOrUpdate: step.enforceOnUpgradeOrUpdate ?? false,
    onClick: () => setCurrentStep((index + 1) as CurrentStep)
  }));

  const isStepCompleted =
    completedStep !== null && completedStep >= currentStep;

  const augmentedConfigMutation = async (
    data: Parameters<typeof configMutation>[0],
    options?: ConfigMutationOptions
  ) => {
    await configMutation({
      ...(!isStepCompleted && !options?.disableAutoStepComplete
        ? { completedStep: currentStep }
        : {}),
      ...data
    });
  };

  useUpdateLayoutEffect(() => {
    pageScrollContainer.scrollTo({ top: 0, left: 0 });
  }, [pageScrollContainer, currentStep]);

  const goToOverview = useCallback(() => setCurrentStep(0), []);

  const goToMyTracking = useCallback(() => {
    const websiteUrl = `/company/${companyId}/website/${websiteId}`;
    const myTrackingUrl = `${websiteUrl}/my-tracking`;
    history.push(myTrackingUrl);
  }, [companyId, websiteId, history]);

  return {
    data,
    steps,
    configMutation: augmentedConfigMutation,
    completedStep,
    currentStep: currentStep as CurrentStep,
    setCurrentStep,
    isStepCompleted,
    goToOverview,
    goToMyTracking
  };
};

/* -------------------------------------------------------------------------- */

type UseSourceSetupFlowDetailsInternalArgs<T extends Source> = {
  source: T;
  eventsConnectorConfig: EventsConnectorConfig;
  stepInfo: StepInfo<T>;
};

const useSourceSetupFlowDetailsInternal = <T extends Source>({
  source,
  eventsConnectorConfig,
  stepInfo
}: UseSourceSetupFlowDetailsInternalArgs<T>) => {
  const { sourceInfo } = useMyTrackingDetails();

  type Config = EventsConnectorConfig[T["configKey"]];
  const config = eventsConnectorConfig[source.configKey] as Config;

  const completedStep = getValidCompletedStep(
    source.stepCount,
    config?.completedStep ??
      (source === sourceCustomPages &&
      sourceInfo.customPages?.state === "UPGRADE_REQUIRED"
        ? 1 // Handles case where upgrade state shows on plan change
        : null)
  ) as EventsConnectorConfigCompletedStep<T>;

  const base = useSetupFlowDetailsInternalBase({
    data: source,
    stepInfo,
    completedStep
  });

  const { goToMyTracking, setCurrentStep } = base;

  useUpdateLayoutEffect(() => {
    if (source === sourceCustomPages && completedStep === null) {
      goToMyTracking();
      toast.success("Source removed");
    } else {
      setCurrentStep(getInitialCurrentStep(stepInfo.length, completedStep));
    }
  }, [source, completedStep, goToMyTracking, setCurrentStep, stepInfo.length]);

  return { type: "SOURCE" as const, ...base, config };
};

/* -------------------------------------------------------------------------- */

type SaveModalState =
  | { isVisible: true; onConfirm: () => Promise<void> }
  | { isVisible: false };

const getDefaultConfigLabel = (
  configs: EventsConnectorConfig[Destination["configKey"]]
) => {
  const fn = (labelToCheck: number | null): string | null => {
    if (labelToCheck === null) {
      if (configs.some(c => c.label === labelToCheck)) {
        return fn(2);
      } else {
        return labelToCheck;
      }
    } else {
      if (configs.some(c => c.label === String(labelToCheck))) {
        return fn(labelToCheck + 1);
      } else {
        return String(labelToCheck);
      }
    }
  };
  return fn(null);
};

type UseDestinationSetupFlowDetailsInternalArgs<T extends Destination> = {
  destination: T;
  eventsConnectorConfig: EventsConnectorConfig;
  stepInfo: StepInfo<T>;
};

const useDestinationSetupFlowDetailsInternal = <T extends Destination>({
  destination,
  eventsConnectorConfig,
  stepInfo
}: UseDestinationSetupFlowDetailsInternalArgs<T>) => {
  const configId = useConfigId();

  const configs = eventsConnectorConfig[destination.configKey];
  type Config = EventsConnectorConfig[T["configKey"]][number] | null;
  const config = (configs.find(c => c.id === configId) ?? null) as Config;

  const [configLabel, setConfigLabel] = useState(() => {
    return config ? config.label : getDefaultConfigLabel(configs);
  });
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  const otherConfigLabels = useMemo(
    () => configs.filter(c => c !== config).map(c => c.label),
    [configs, config]
  );

  const completedStep = getValidCompletedStep(
    destination.stepCount,
    config?.completedStep ?? null
  ) as EventsConnectorConfigCompletedStep<T>;

  const base = useSetupFlowDetailsInternalBase({
    data: destination,
    stepInfo,
    completedStep
  });

  const configMutation: typeof base.configMutation = async (data, options) => {
    const action = async () => {
      await base.configMutation(data, options);

      if (base.isStepCompleted && !("completedStep" in data)) {
        toast.success("Destination updated");
      }
    };

    if (config?.live && !("live" in data)) {
      setSaveModalState({ isVisible: true, onConfirm: action });
    } else {
      if (!options?.disableAutoLoading) setIsLoading(true);
      await action();
      if (!options?.disableAutoLoading) setIsLoading(false);
    }
  };

  const { goToMyTracking, setCurrentStep } = base;

  useUpdateLayoutEffect(() => {
    if (configId && completedStep === null) {
      goToMyTracking();
      toast.success("Destination removed");
    } else {
      setCurrentStep(getInitialCurrentStep(stepInfo.length, completedStep));
    }
  }, [
    configId,
    completedStep,
    goToMyTracking,
    setCurrentStep,
    stepInfo.length
  ]);

  useUpdateLayoutEffect(() => {
    if (config) setConfigLabel(config.label);
  }, [config]);

  return {
    type: "DESTINATION" as const,
    ...base,
    config,
    configMutation,
    configLabel,
    setConfigLabel,
    otherConfigLabels,
    saveModalState,
    closeSaveModal: () => setSaveModalState({ isVisible: false }),
    isLoading,
    setIsLoading
  };
};

/* ========================================================================== */

export type SetupFlowDetails<
  T extends Source | Destination = Source | Destination
> = T extends Source
  ? ReturnType<typeof useSourceSetupFlowDetailsInternal<T>>
  : T extends Destination
    ? ReturnType<typeof useDestinationSetupFlowDetailsInternal<T>>
    : never;

type SetupFlowInternalProps = {
  isCompanyAdmin: boolean;
  onRemove?: () => OptionalPromise<void>;
  details: SetupFlowDetails;
  children: React.ReactNode;
};

const SetupFlowInternal: React.FC<SetupFlowInternalProps> = ({
  isCompanyAdmin,
  onRemove,
  details,
  children
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { sourceInfo } = useMyTrackingDetails();

  const [hoveredStep, setHoveredStep] = useState<number | null>(null);
  const [isSaveModalLoading, setIsSaveModalLoading] = useState(false);

  const name = details.data.name;
  const subject =
    details.type === "SOURCE"
      ? `${name} Source`
      : `${formatTitle(name, details.configLabel)} Destination`;

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;
  const historyUrl = `${websiteUrl}/settings/history?subjectDefault=${subject}`;

  const firstVisibleStep = details.steps.findIndex(s => s.isVisible) + 1;
  const hiddenCompletedStepCount = details.steps
    .slice(0, details.completedStep ?? 0)
    .filter(s => !s.isVisible).length;
  const adjustedCompletedStep =
    (details.completedStep ?? 0) - hiddenCompletedStepCount;
  const adjustedStepCount = details.steps.filter(s => s.isVisible).length;

  const isSetup = details.completedStep === details.data.stepCount;

  const isUpgradeAvailable =
    details.type === "SOURCE"
      ? name === "Shopify"
        ? sourceInfo.shopify?.state === "UPGRADE_REQUIRED"
        : sourceInfo.customPages?.state === "UPGRADE_REQUIRED"
      : false;

  const isUpgradeInProgress =
    details.type === "SOURCE"
      ? name === "Shopify"
        ? (sourceInfo.shopify?.state === "UPGRADE_REQUIRED" &&
            sourceInfo.shopify.isInProgress) ||
          (sourceInfo.shopify?.state === "NOT_SETUP" &&
            sourceInfo.shopify.wasAlreadyInstalled &&
            details.completedStep !== null)
        : sourceInfo.customPages?.state === "UPGRADE_REQUIRED" &&
          sourceInfo.customPages.isInProgress
      : false;

  const isUpdateAvailable =
    details.type === "SOURCE" && name === "Shopify"
      ? sourceInfo.shopify?.state === "UPDATE_REQUIRED"
      : false;

  const isUpdateInProgress =
    details.type === "SOURCE" && name === "Shopify"
      ? (sourceInfo.shopify?.state === "UPDATE_REQUIRED" &&
          sourceInfo.shopify.isInProgress) ||
        (sourceInfo.shopify?.state === "NOT_SETUP" &&
          sourceInfo.shopify.wasAlreadyInstalled &&
          details.completedStep !== null)
      : false;

  // Handles the intermediate render after deleting a source/destination
  if (details.currentStep !== 0 && details.config === null) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <>
      <SetupFlowWrapper>
        <div>
          <div>
            <PageBackLink to={myTrackingUrl} />
          </div>
          <div>
            <SetupFlowHeading details={details} />
            {details.config && details.completedStep !== null ? (
              <SetupFlowActions>
                <div>
                  {isCompanyAdmin ? (
                    <StyledLinkExternal href={historyUrl} text="View History" />
                  ) : null}
                </div>
                <div>
                  {(() => {
                    switch (details.type) {
                      case "SOURCE": {
                        const isDetectedOnLiveTheme =
                          name === "Shopify" &&
                          (sourceInfo.shopify?.state === "SETUP" ||
                            sourceInfo.shopify?.state === "UPDATE_REQUIRED")
                            ? sourceInfo.shopify.isDetectedOnLiveTheme
                            : null;

                        return isDetectedOnLiveTheme ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="DETECTED_ON_LIVE_THEME"
                          />
                        ) : isDetectedOnLiveTheme === false ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="NOT_DETECTED_ON_LIVE_THEME"
                          />
                        ) : name !== "Shopify" && isSetup ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="COMPLETED"
                          />
                        ) : null;
                      }
                      case "DESTINATION": {
                        const isTestModeEnabled =
                          "testCode" in details.config
                            ? Boolean(details.config.testCode)
                            : "testMode" in details.config
                              ? details.config.testMode
                              : false;
                        const isLive = Boolean(details.config.live);

                        return isTestModeEnabled ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="TEST_MODE"
                          />
                        ) : isLive ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="LIVE"
                          />
                        ) : isSetup ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="OFFLINE"
                          />
                        ) : null;
                      }
                    }
                  })()}
                </div>
                <div>
                  {(() => {
                    switch (details.type) {
                      case "SOURCE": {
                        const wasAlreadyInstalled =
                          name === "Shopify" &&
                          sourceInfo.shopify?.state === "NOT_SETUP"
                            ? sourceInfo.shopify.wasAlreadyInstalled
                            : null;

                        return isUpgradeInProgress && !wasAlreadyInstalled ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="UPGRADE_MODE"
                          />
                        ) : isUpgradeAvailable ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="UPGRADE_AVAILABLE"
                          />
                        ) : isUpdateInProgress && !wasAlreadyInstalled ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="UPDATE_MODE"
                          />
                        ) : isUpdateAvailable ? (
                          <StatusButton
                            onClick={details.goToOverview}
                            type="UPDATE_AVAILABLE"
                          />
                        ) : null;
                      }
                      case "DESTINATION":
                        return null;
                    }
                  })()}
                </div>
                {name !== "Shopify" ? (
                  <div>
                    <ActionsButtonDropdown
                      details={details}
                      onRemove={onRemove}
                    />
                  </div>
                ) : null}
              </SetupFlowActions>
            ) : null}
          </div>
        </div>
        <div>
          <div>
            <NavigationPageCard>
              <NavigationHeader>
                <NavigationHeading>Setup Steps</NavigationHeading>
                <NavigationCompletedCount>
                  {adjustedCompletedStep}/{adjustedStepCount}
                </NavigationCompletedCount>
              </NavigationHeader>
              <ButtonsWrapper>
                <OverviewButton
                  isActive={details.currentStep === 0}
                  onClick={details.goToOverview}
                >
                  <div>
                    <IconHouse size="24px" />
                  </div>
                  <div>Overview</div>
                </OverviewButton>
                <StepButtonsWrapper>
                  <div>
                    {details.steps.map((step, index) =>
                      step.isVisible ? (
                        <StepButton
                          key={index}
                          disabled={
                            ((!isUpgradeInProgress && !isUpdateInProgress) ||
                              step.isRequiredOnUpgradeOrUpdate) &&
                            (details.completedStep === null ||
                              index > details.completedStep)
                          }
                          isActive={details.currentStep === index + 1}
                          onMouseEnter={() => setHoveredStep(index + 1)}
                          onMouseLeave={() => setHoveredStep(null)}
                          onClick={step.onClick}
                          isDeemphasized={
                            (isUpgradeInProgress || isUpdateInProgress) &&
                            !step.isRequiredOnUpgradeOrUpdate
                          }
                        >
                          {step.text}
                        </StepButton>
                      ) : null
                    )}
                  </div>
                  <div aria-hidden="true">
                    <StepTimelineWrapper>
                      {details.steps.map((step, index) =>
                        step.isVisible ? (
                          <StepTimelineItem
                            key={index}
                            firstVisibleStep={firstVisibleStep}
                            step={index + 1}
                            stepCount={details.steps.length}
                            completedStep={details.completedStep}
                            hoveredStep={hoveredStep}
                            currentStep={details.currentStep}
                            isUpgradeInProgress={isUpgradeInProgress}
                            isUpdateInProgress={isUpdateInProgress}
                            isRequiredOnUpgradeOrUpdate={
                              step.isRequiredOnUpgradeOrUpdate
                            }
                          />
                        ) : null
                      )}
                    </StepTimelineWrapper>
                  </div>
                </StepButtonsWrapper>
              </ButtonsWrapper>
              <ContactCalloutWrapper>
                <div>
                  <ContactCallout linkType="APP_SUPPORT_PAGE" />
                </div>
              </ContactCalloutWrapper>
            </NavigationPageCard>
          </div>
          <div>{children}</div>
        </div>
      </SetupFlowWrapper>
      {details.type === "DESTINATION" && details.config?.live ? (
        <Modal
          isVisible={details.saveModalState.isVisible}
          onClose={() => details.closeSaveModal()}
          disallowClose={isSaveModalLoading}
        >
          <SaveModalContents>
            <SaveModalTitle>Are you sure?</SaveModalTitle>
            <SaveModalBody>
              This integration is live. Any changes made to its configuration
              while it is live will immediately affect transactions processed by
              this integration.
            </SaveModalBody>
            <SaveModalButtons>
              <ButtonSecondary
                variant="SMALL"
                state={isSaveModalLoading ? "DISABLED" : "IDLE"}
                onClick={() => details.closeSaveModal()}
              >
                No, Go Back
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                state={isSaveModalLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  if (details.saveModalState.isVisible) {
                    setIsSaveModalLoading(true);
                    await details.saveModalState.onConfirm();
                    details.closeSaveModal();
                    setIsSaveModalLoading(false);
                  }
                }}
              >
                Yes, Save Changes
              </ButtonPrimary>
            </SaveModalButtons>
          </SaveModalContents>
        </Modal>
      ) : null}
    </>
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SetupFlowWrapper = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;

  > div:first-child {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: ${props => props.theme.palette.grey7};
    padding-top: ${props => props.theme.gridBase * 3.5}px;

    > div:first-child {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }

    > div:last-child {
      display: grid;
      justify-content: space-between;
      grid-template-columns: minmax(0, auto) auto;
      gap: ${props => props.theme.gridBase * 4}px;
    }
  }

  > div:last-child {
    display: grid;
    grid-template-columns:
      minmax(
        ${props => props.theme.gridBase * 38}px,
        ${props => props.theme.gridBase * 50}px
      )
      minmax(${props => props.theme.gridBase * 65}px, auto);
    column-gap: ${props => props.theme.gridBase * 4}px;
    align-items: start;

    > div:first-child {
      position: sticky;
      top: ${props => props.theme.gridBase * 15}px;
    }

    > div:last-child {
      padding-bottom: ${props => props.theme.gridBase * 30}px;
    }
  }
`;

const PageBackLink = styled(BackLink)`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const SetupFlowActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${props => props.theme.gridBase * 1.5}px;
  height: ${props => props.theme.gridBase * 3.5}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:nth-child(2):not(:empty),
  > div:nth-child(3):not(:empty) {
    margin-left: ${props => props.theme.gridBase}px;
  }
`;

const NavigationPageCard = styled(PageCard)`
  padding: 0;
`;

const NavigationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${props => props.theme.gridBase * 3}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
`;

const NavigationHeading = styled.div`
  ${heading3Styles};
`;

const NavigationCompletedCount = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey4};
`;

const ButtonsWrapper = styled.div`
  padding: 0 ${props => props.theme.gridBase * 1.5}px;
`;

type NavigationButtonStylesProps = {
  isActive: boolean;
  isDeemphasized?: boolean;
};

const navigationButtonStyles = css<NavigationButtonStylesProps>`
  border-radius: 4px;
  width: 100%;
  display: flex;
  padding: ${props => props.theme.gridBase * 1.25}px;
  background-color: ${props =>
    props.isActive ? props.theme.palette.grey8 : props.theme.palette.white};
  transition:
    background-color ${props => props.theme.other.transition},
    color ${props => props.theme.other.transition};

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  &:disabled {
    color: ${props => props.theme.palette.grey4};
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: ${props => props.theme.palette.grey8};
  }
`;

const OverviewButton = styled.button<NavigationButtonStylesProps>`
  ${navigationButtonStyles};

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.grey3};
    margin-right: ${props => props.theme.gridBase * 1.25}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    font-weight: 500;
  }
`;

const StepButtonsWrapper = styled.div`
  position: relative;

  > div:last-child {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
`;

const StepButton = styled.button<NavigationButtonStylesProps>`
  ${navigationButtonStyles};
  ${normalBodyStyles};
  font-weight: 500;
  padding-left: ${props => props.theme.gridBase * 5.5}px;
  color: ${props =>
    props.isDeemphasized
      ? transparentize(0.5, props.theme.palette.grey1)
      : props.theme.palette.grey1};
`;

const StepTimelineWrapper = styled.div`
  width: ${props => props.theme.gridBase * 5.5}px;
  margin-top: ${props => props.theme.gridBase * 1.5}px;
`;

const ContactCalloutWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;

  > div {
    border-top: 1px solid ${props => props.theme.palette.grey7};
    padding-top: ${props => props.theme.gridBase * 2}px;
    padding-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const SaveModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const SaveModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const SaveModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const SaveModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

type SetupFlowHeadingProps = {
  details: SetupFlowDetails;
};

const SetupFlowHeading: React.FC<SetupFlowHeadingProps> = ({ details }) => {
  const theme = useTheme();

  const name = details.data.name;

  const [isLoading, setIsLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [labelDraft, setLabelDraft] = useState(emptyStringCleaveValues);

  const state = "state" in details.data ? details.data.state : "STABLE";
  const trimmedRawLabel = labelDraft.rawValue.trim();
  const newLabel = trimmedRawLabel === "" ? null : trimmedRawLabel;

  return (
    <>
      <SetupFlowHeadingWrapper>
        <div>
          <div>
            <details.data.icon size="24px" />
          </div>
          {details.type === "DESTINATION" ? (
            <Tooltip text="Click to rename" placement="top" delay={[150, 0]}>
              <SetupFlowHeadingTooltipInner>
                <SetupFlowHeadingButton onClick={() => setIsModalShown(true)}>
                  {formatTitle(name, details.configLabel)}
                </SetupFlowHeadingButton>
              </SetupFlowHeadingTooltipInner>
            </Tooltip>
          ) : (
            <SetupFlowHeadingText>{name}</SetupFlowHeadingText>
          )}
        </div>
        {state !== "STABLE" ? (
          <Status
            textColor={theme.palette.white}
            backgroundColor={theme.palette.grey5}
            text={capitalize(state)}
          />
        ) : null}
      </SetupFlowHeadingWrapper>
      {details.type === "DESTINATION" ? (
        <Modal
          isVisible={isModalShown}
          onClose={() => setIsModalShown(false)}
          disallowClose={isLoading}
        >
          <ModalContents>
            <ModalTitle>Rename Your Destination</ModalTitle>
            <SetupFlowHeadingModalBody>
              How this destination will appear in our app:
            </SetupFlowHeadingModalBody>
            <SetupFlowHeadingModalInputWrapper>
              <InputFieldDestinationName
                variant="SMALL"
                prefix={name}
                value={labelDraft.rawValue}
                onInit={() => {
                  setLabelDraft({
                    prettyValue: formatTitle(name, details.configLabel),
                    rawValue: details.configLabel ?? ""
                  });
                }}
                onChange={event => {
                  setLabelDraft({
                    prettyValue: event.target.value,
                    rawValue: event.target.rawValue
                  });
                }}
              />
              <div>{labelDraft.rawValue.length}/20 characters</div>
            </SetupFlowHeadingModalInputWrapper>
            <ModalButtons>
              <ButtonSecondary
                variant="SMALL"
                state={isLoading ? "DISABLED" : "IDLE"}
                onClick={() => setIsModalShown(false)}
              >
                Go Back
              </ButtonSecondary>
              <Tooltip
                text="This name is already being used"
                placement="top"
                disabled={!details.otherConfigLabels.includes(newLabel)}
              >
                <SetupFlowHeadingModalTooltipInner>
                  <ButtonPrimary
                    variant="SMALL"
                    state={
                      isLoading
                        ? "LOADING"
                        : details.otherConfigLabels.includes(newLabel)
                          ? "DISABLED"
                          : "IDLE"
                    }
                    onClick={async () => {
                      setIsLoading(true);

                      if (details.config) {
                        await details.configMutation({ label: newLabel });
                      } else {
                        details.setConfigLabel(newLabel);
                      }

                      setIsModalShown(false);
                      setIsLoading(false);
                      track.destinationRename(details.data.shorthand);
                    }}
                  >
                    Save
                  </ButtonPrimary>
                </SetupFlowHeadingModalTooltipInner>
              </Tooltip>
            </ModalButtons>
          </ModalContents>
        </Modal>
      ) : null}
    </>
  );
};

const SetupFlowHeadingWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 2}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 1.5}px;
    overflow-x: hidden;

    > div:first-child {
      color: ${props => props.theme.palette.purple2};
    }
  }
`;

const SetupFlowHeadingTooltipInner = styled.div`
  display: flex;
  overflow-x: hidden;
`;

const SetupFlowHeadingButton = styled.button`
  ${heading2Styles};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const SetupFlowHeadingText = styled.div`
  ${heading2Styles};
`;

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 40}px;
  position: relative;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const SetupFlowHeadingModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const SetupFlowHeadingModalInputWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:last-child {
    ${smallTextStyles};
    text-align: right;
    color: ${props => props.theme.palette.grey2};
    margin-top: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

const SetupFlowHeadingModalTooltipInner = styled.div`
  > button {
    width: 100%;
  }
`;

/* ========================================================================== */

type StepTimelineItemState =
  | "UPGRADE_OR_UPDATE_COMPLETED"
  | "UPGRADE_OR_UPDATE_ACTIVE"
  | "UPGRADE_OR_UPDATE_TO_COMPLETE"
  | "NON_UPGRADE_OR_UPDATE_COMPLETED"
  | "NON_UPGRADE_OR_UPDATE_ACTIVE"
  | "NON_UPGRADE_OR_UPDATE_NEXT"
  | "NON_UPGRADE_OR_UPDATE_DISABLED";

type StepTimelineItemProps = {
  firstVisibleStep: number;
  step: number;
  stepCount: number;
  completedStep: number | null;
  hoveredStep: number | null;
  currentStep: number;
  isUpgradeInProgress: boolean;
  isUpdateInProgress: boolean;
  isRequiredOnUpgradeOrUpdate: boolean;
};

const StepTimelineItem: React.FC<StepTimelineItemProps> = ({
  firstVisibleStep,
  step,
  stepCount,
  completedStep,
  hoveredStep,
  currentStep,
  isUpgradeInProgress,
  isUpdateInProgress,
  isRequiredOnUpgradeOrUpdate
}) => {
  const isCompleted = completedStep !== null && completedStep >= step;
  const isActive = currentStep === step;

  const state: StepTimelineItemState =
    isUpgradeInProgress || isUpdateInProgress
      ? isCompleted || !isRequiredOnUpgradeOrUpdate
        ? "UPGRADE_OR_UPDATE_COMPLETED"
        : isActive
          ? "UPGRADE_OR_UPDATE_ACTIVE"
          : "UPGRADE_OR_UPDATE_TO_COMPLETE"
      : isCompleted
        ? "NON_UPGRADE_OR_UPDATE_COMPLETED"
        : isActive
          ? "NON_UPGRADE_OR_UPDATE_ACTIVE"
          : completedStep === step - 1
            ? "NON_UPGRADE_OR_UPDATE_NEXT"
            : "NON_UPGRADE_OR_UPDATE_DISABLED";

  return (
    <StepTimelineItemWrapper step={step} stepCount={stepCount}>
      {step !== firstVisibleStep ? (
        <StepTimelineItemLine state={state} />
      ) : null}
      <StepTimelineItemPoint state={state} isHovered={hoveredStep === step}>
        <PointInnerCheckMark state={state}>
          <IconCheckMark size="16px" />
        </PointInnerCheckMark>
        <PointInnerCircle state={state} />
      </StepTimelineItemPoint>
    </StepTimelineItemWrapper>
  );
};

type StepTimelineItemWrapperProps = {
  step: number;
  stepCount: number;
};

const StepTimelineItemWrapper = styled.div<StepTimelineItemWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${props => props.stepCount + 1 - props.step};
`;

type StepTimelineItemLineProps = {
  state: StepTimelineItemState;
};

const StepTimelineItemLine = styled.div<StepTimelineItemLineProps>`
  width: ${props => props.theme.gridBase * 0.25}px;
  height: ${props => props.theme.gridBase * 6}px;
  margin: ${props => props.theme.gridBase * -1}px 0;
  transition: background-color ${props => props.theme.other.transition};
  background-color: ${props =>
    props.state === "NON_UPGRADE_OR_UPDATE_DISABLED"
      ? props.theme.palette.grey8
      : props.theme.palette.purple2};
  z-index: ${props =>
    props.state === "UPGRADE_OR_UPDATE_ACTIVE" ||
    props.state === "UPGRADE_OR_UPDATE_TO_COMPLETE"
      ? 0
      : 2};
`;

type StepTimelineItemPointProps = {
  state: StepTimelineItemState;
  isHovered: boolean;
};

const StepTimelineItemPoint = styled.div<StepTimelineItemPointProps>`
  z-index: 1;
  position: relative;
  width: ${props => props.theme.gridBase * 2.5}px;
  height: ${props => props.theme.gridBase * 2.5}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color ${props => props.theme.other.transition};
  background-color: ${props =>
    props.state === "UPGRADE_OR_UPDATE_COMPLETED" ||
    props.state === "NON_UPGRADE_OR_UPDATE_COMPLETED"
      ? props.theme.palette.purple2
      : props.state === "UPGRADE_OR_UPDATE_ACTIVE" ||
          props.state === "NON_UPGRADE_OR_UPDATE_ACTIVE" ||
          props.isHovered
        ? props.theme.palette.white
        : props.theme.palette.grey8};
`;

type PointInnerCheckMarkProps = {
  state: StepTimelineItemState;
};

const PointInnerCheckMark = styled.div<PointInnerCheckMarkProps>`
  position: absolute;
  display: flex;
  color: ${props => props.theme.palette.white};
  visibility: ${props =>
    props.state === "UPGRADE_OR_UPDATE_COMPLETED" ||
    props.state === "NON_UPGRADE_OR_UPDATE_COMPLETED"
      ? "visible"
      : "hidden"};
  opacity: ${props =>
    props.state === "UPGRADE_OR_UPDATE_COMPLETED" ||
    props.state === "NON_UPGRADE_OR_UPDATE_COMPLETED"
      ? 1
      : 0};
  transition:
    visibility ${props => props.theme.other.transition},
    opacity ${props => props.theme.other.transition};
`;

type PointInnerCircleProps = {
  state: StepTimelineItemState;
};

const PointInnerCircle = styled.div<PointInnerCircleProps>`
  position: absolute;
  width: ${props => props.theme.gridBase}px;
  height: ${props => props.theme.gridBase}px;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.purple2};
  visibility: ${props =>
    props.state === "UPGRADE_OR_UPDATE_ACTIVE" ||
    props.state === "NON_UPGRADE_OR_UPDATE_ACTIVE" ||
    props.state === "NON_UPGRADE_OR_UPDATE_NEXT"
      ? "visible"
      : "hidden"};
  opacity: ${props =>
    props.state === "UPGRADE_OR_UPDATE_ACTIVE" ||
    props.state === "NON_UPGRADE_OR_UPDATE_ACTIVE" ||
    props.state === "NON_UPGRADE_OR_UPDATE_NEXT"
      ? 1
      : 0};
  transition:
    visibility ${props => props.theme.other.transition},
    opacity ${props => props.theme.other.transition};
`;

/* ========================================================================== */

type StatusButtonType =
  | "UPGRADE_MODE"
  | "UPGRADE_AVAILABLE"
  | "UPDATE_MODE"
  | "UPDATE_AVAILABLE"
  | "DETECTED_ON_LIVE_THEME"
  | "NOT_DETECTED_ON_LIVE_THEME"
  | "COMPLETED"
  | "TEST_MODE"
  | "LIVE"
  | "OFFLINE";

type StatusButtonProps = {
  onClick: () => void;
  type: StatusButtonType;
};

const StatusButton: React.FC<StatusButtonProps> = ({ onClick, type }) => {
  const theme = useTheme();

  return (
    <button onClick={onClick}>
      {(() => {
        switch (type) {
          case "UPGRADE_MODE":
            return (
              <Status
                textColor={theme.palette.white}
                backgroundColor={theme.palette.orange}
                icon={<IconRefresh size="16px" />}
                text="Upgrade Mode"
              />
            );
          case "UPGRADE_AVAILABLE":
            return (
              <Status
                textColor={theme.palette.orange}
                backgroundColor={transparentize(0.84, theme.palette.orange)}
                icon={<IconBolt size="16px" />}
                text="Upgrade Available"
              />
            );
          case "UPDATE_MODE":
            return (
              <Status
                textColor={theme.palette.white}
                backgroundColor={theme.palette.orange}
                icon={<IconRefresh size="16px" />}
                text="Update Mode"
              />
            );
          case "UPDATE_AVAILABLE":
            return (
              <Status
                textColor={theme.palette.orange}
                backgroundColor={transparentize(0.84, theme.palette.orange)}
                icon={<IconBolt size="16px" />}
                text="Update Available"
              />
            );
          case "DETECTED_ON_LIVE_THEME":
            return (
              <Status
                textColor={theme.palette.white}
                backgroundColor={theme.palette.green}
                icon={<IconCheckMark size="16px" />}
                text="Detected on Live Theme"
              />
            );
          case "NOT_DETECTED_ON_LIVE_THEME":
            return (
              <Status
                textColor={theme.palette.white}
                backgroundColor={theme.palette.orange}
                icon={<IconCircledInfo size="16px" />}
                text="Not Detected on Live Theme"
              />
            );
          case "COMPLETED":
            return (
              <Status
                textColor={theme.palette.white}
                backgroundColor={theme.palette.green}
                icon={<IconCheckMark size="16px" />}
                text="Completed"
              />
            );
          case "TEST_MODE":
            return (
              <Status
                textColor={theme.palette.white}
                backgroundColor={theme.palette.orange}
                icon={<IconCoffee size="16px" />}
                text="Test Mode"
              />
            );
          case "LIVE":
            return (
              <Status
                textColor={theme.palette.white}
                backgroundColor={theme.palette.green}
                icon={<IconCheckMark size="16px" />}
                text="Live"
              />
            );
          case "OFFLINE":
            return (
              <Status
                textColor={theme.palette.white}
                backgroundColor={theme.palette.grey5}
                icon={<IconCircledPause size="16px" />}
                text="Offline"
              />
            );
        }
      })()}
    </button>
  );
};

/* ========================================================================== */

type ActionsButtonDropdownProps = {
  details: SetupFlowDetails;
  onRemove?: () => OptionalPromise<void>;
};

const ActionsButtonDropdown: React.FC<ActionsButtonDropdownProps> = ({
  details,
  onRemove
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <ActionsButtonDropdownInternal
        dropdownPlacement="bottom-end"
        dropdownOptions={[
          {
            value: `Remove ${capitalize(details.type)}`,
            type: "BUTTON",
            onClick: () => setIsModalVisible(true),
            ...(details.type === "DESTINATION" && details.config?.live
              ? {
                  disabled: true,
                  tooltipContent: "Please go offline first"
                }
              : details.type === "DESTINATION" &&
                  details.config !== null &&
                  (("testCode" in details.config && details.config.testCode) ||
                    ("testMode" in details.config && details.config.testMode))
                ? {
                    disabled: true,
                    tooltipContent: "Please exit test mode first"
                  }
                : {})
          }
        ]}
      >
        <IconDotsHorizontal size="24px" />
      </ActionsButtonDropdownInternal>
      <Modal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        <ModalContents>
          <ModalTitle>Remove {capitalize(details.type)}</ModalTitle>
          <ModalBody>
            By continuing, we will remove this {lowerCase(details.type)}{" "}
            configuration entirely. This cannot be undone.
          </ModalBody>
          <ModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setIsModalVisible(false)}
            >
              Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                setIsLoading(true);
                await onRemove?.();
                await details.configMutation(
                  { completedStep: null },
                  { disableAutoLoading: true }
                );
              }}
            >
              Remove
            </ButtonPrimary>
          </ModalButtons>
        </ModalContents>
      </Modal>
    </>
  );
};

const ActionsButtonDropdownInternal = styled(ButtonDropdown)`
  ${iconButtonStyles};
  padding: ${props => props.theme.gridBase * 0.25}px;
  background: ${props => props.theme.palette.white};

  &:hover {
    background: ${props => props.theme.palette.white};
  }
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const setupFlowDetailsContext = createContext<SetupFlowDetails | undefined>(
  undefined
);

const useSetupFlowDetails = <T extends Source | Destination>() => {
  type Details = T extends Source
    ? ReturnType<typeof useSourceSetupFlowDetailsInternal<T>>
    : T extends Destination
      ? ReturnType<typeof useDestinationSetupFlowDetailsInternal<T>>
      : never;

  const setupFlowDetails = useContext(setupFlowDetailsContext);

  if (setupFlowDetails !== undefined) {
    return setupFlowDetails as Details;
  } else {
    throw new Error("`useSetupFlowDetails`: value not set");
  }
};

const useConfigRequired = <T extends Source | Destination>() => {
  const { config } = useSetupFlowDetails();

  if (config !== null) {
    return config as SingularEventsConnectorConfig<T>;
  } else {
    throw new Error("`useConfigRequired`: value not set");
  }
};

const setupFlowContextContext = createContext<unknown>(undefined);

const useSetupFlowContext = <TContext,>() => {
  const setupFlowContext = useContext(setupFlowContextContext);

  if (setupFlowContext !== undefined) {
    return setupFlowContext as TContext;
  } else {
    throw new Error("`useSetupFlowContext`: value not set");
  }
};

type SetupFlowProps<
  T extends Source | Destination,
  TContext extends Record<string, unknown> | undefined
> = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  stepInfo: StepInfo<T>;
  onRemove?: () => OptionalPromise<void>;
  children: React.ReactNode;
} & (TContext extends undefined ? EmptyObject : { context: TContext });

type SetupFlow<
  T extends Source | Destination,
  TContext extends Record<string, unknown> | undefined
> = {
  SetupFlow: React.FC<SetupFlowProps<T, TContext>>;
  useConfigRequired: typeof useConfigRequired<T>;
  useSetupFlowDetails: typeof useSetupFlowDetails<T>;
  useSetupFlowContext: typeof useSetupFlowContext<TContext>;
};

const createSourceSetupFlow = <
  T extends Source,
  TContext extends Record<string, unknown> | undefined
>(
  source: T
): SetupFlow<T, TContext> => {
  return {
    SetupFlow: props => {
      const details = useSourceSetupFlowDetailsInternal({
        source,
        eventsConnectorConfig: props.eventsConnectorConfig,
        stepInfo: props.stepInfo
      }) as unknown as SetupFlowDetails;
      return (
        <setupFlowDetailsContext.Provider value={details}>
          <setupFlowContextContext.Provider
            value={"context" in props ? props.context : undefined}
          >
            <SetupFlowInternal
              isCompanyAdmin={props.isCompanyAdmin}
              onRemove={props.onRemove}
              details={details}
            >
              {props.children}
            </SetupFlowInternal>
          </setupFlowContextContext.Provider>
        </setupFlowDetailsContext.Provider>
      );
    },
    useConfigRequired: useConfigRequired<T>,
    useSetupFlowDetails: useSetupFlowDetails<T>,
    useSetupFlowContext: useSetupFlowContext<TContext>
  };
};

const createDestinationSetupFlow = <
  T extends Destination,
  TContext extends Record<string, unknown> | undefined
>(
  destination: T
): SetupFlow<T, TContext> => {
  return {
    SetupFlow: props => {
      const details = useDestinationSetupFlowDetailsInternal({
        destination,
        eventsConnectorConfig: props.eventsConnectorConfig,
        stepInfo: props.stepInfo
      }) as unknown as SetupFlowDetails;
      return (
        <setupFlowDetailsContext.Provider value={details}>
          <setupFlowContextContext.Provider
            value={"context" in props ? props.context : undefined}
          >
            <SetupFlowInternal
              isCompanyAdmin={props.isCompanyAdmin}
              onRemove={props.onRemove}
              details={details}
            >
              {props.children}
            </SetupFlowInternal>
          </setupFlowContextContext.Provider>
        </setupFlowDetailsContext.Provider>
      );
    },
    useConfigRequired: useConfigRequired<T>,
    useSetupFlowDetails: useSetupFlowDetails<T>,
    useSetupFlowContext: useSetupFlowContext<TContext>
  };
};

export const createSetupFlow = <
  const TContext extends Record<string, unknown> | undefined = undefined
>() => {
  return {
    source: <T extends Source>(source: T) => {
      return createSourceSetupFlow<T, TContext>(source);
    },
    destination: <T extends Destination>(destination: T) => {
      return createDestinationSetupFlow<T, TContext>(destination);
    }
  };
};
