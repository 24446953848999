import { uniq } from "lodash-es";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { InputFieldNumber } from "../../components/InputFieldNumber";
import { PageCard } from "../../components/PageCard";
import { createSetupFlow } from "../../context/SetupFlowDetails";
import { ConsentMode } from "./ConsentMode";
import { destinationCja as destination } from "./data";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-cj-affiliate-in-elevar-server-side-destination";

const { SetupFlow, useConfigRequired, useSetupFlowDetails } =
  createSetupFlow().destination(destination);

type DestinationCjaProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
};

export const DestinationCja: React.FC<DestinationCjaProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig
}) => {
  return (
    <SetupFlow
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      stepInfo={[
        { name: "CJ Affiliate Settings" },
        { name: "Consent Mode" },
        { name: "Transaction Identifier" },
        { name: "Product Identifier" },
        { name: "Filter Transactions" },
        { name: "Subscriptions" }
      ]}
    >
      <StepContent />
    </SetupFlow>
  );
};

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
  }
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const setupFlow = useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={setupFlow.config}
      configMutation={setupFlow.configMutation}
      configLabel={setupFlow.configLabel}
      completedStep={setupFlow.completedStep}
      isStepCompleted={setupFlow.isStepCompleted}
      setCurrentStep={setupFlow.setCurrentStep}
      getConfigSummaryItems={config => [
        {
          step: 1,
          type: "CUSTOM",
          render: () => (
            <>
              The CJ Enterprise ID used is <span>{config.adAccountId}</span>
            </>
          )
        },
        {
          step: 2,
          type: "CONSENT_MODE",
          inEnabled: config.consentMode.enabled
        },
        {
          step: 3,
          type: "TRANSACTION_IDENTIFIER",
          value: config.dataConfig.orderAttributeId
        },
        {
          step: 4,
          type: "PRODUCT_IDENTIFIER",
          value: config.dataConfig.productAttributeMapping
        },
        {
          step: 5,
          type: "FILTER_TRANSACTIONS",
          filters: config.orderFilters
        },
        {
          step: 6,
          type: "SUBSCRIPTIONS",
          filters: config.orderFilters,
          tagName: config.subscriptionTagName
        }
      ]}
      description={
        <Step0Explainer>
          Guarantee near 100% accuracy for CJ Affiliate transactions by
          configuring this server-side integration.
        </Step0Explainer>
      }
      integrationNotes={
        <div>
          To set up this integration, connect with CJ Affiliate's support team
          to set up client-side tracking for you. CJ Affiliate recommends
          running client-side tracking in parallel with this server-side
          integration. After launch, have your CJ Affiliate representative
          confirm that the client- and server-side tracking works as expected.
        </div>
      }
      whatsNextOverrides={{
        title: "Confirm Setup with CJ Support",
        explainer: (
          <>
            CJ wants you to send client-side data (that CJ likely helped you set
            up already) in tandem with the server-side data. So long as they
            both use Order ID, duplicate transactions will be deduplicated.
            Confirm with CJ that your client-side tracking is sending Order ID.
          </>
        ),
        media: { type: "NONE", spanContent: true },
        link: {
          href: "https://docs.getelevar.com/docs/how-to-remove-previous-cj-affiliate-tracking",
          text: "Confirm Client-Side Tracking Sends Order ID"
        }
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [adAccountId, setAdAccountId] = useState(config.adAccountId);
  const [accessToken, setAccessToken] = useState(config.accessToken);
  const [eventMapping, setEventMapping] = useState(config.eventMapping);

  const areCJAffiliateFieldsFilledIn =
    adAccountId !== null &&
    accessToken !== "" &&
    eventMapping.purchase !== null;

  const areCJAffiliateFieldsUnique =
    uniq([adAccountId, accessToken, eventMapping.purchase]).length === 3;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="CJ Affiliate Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              Please provide the following details from your CJ Affiliate
              account:
            </Step1Explainer>
          }
        >
          <Step1SectionInnerWrapper>
            <div>
              <InputWrapper
                labelText="CJ Enterprise ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 31}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      Please contact your CJ account rep for your Enterprise ID.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldNumber
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={adAccountId ?? ""}
                  onChange={event => {
                    const value = event.target.rawValue;
                    setAdAccountId(value === "" ? null : Number(value));
                  }}
                  placeholder="1234567"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Personal Access Token"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 42.5}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>
                        This is a unique identifier for your CJ account. It
                        allows for secure authentication.
                      </p>
                      <StyledLinkExternal
                        href="https://developers.cj.com/account/personal-access-tokens"
                        text="How do I find this?"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={accessToken}
                  onChange={event => setAccessToken(event.target.value)}
                  placeholder="aa1aa111aaA11aa1aa111aaA1a"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Action Tracker ID"
                disabled={setupFlow.isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 31}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      Please contact your CJ account rep for your Action Tracker
                      ID.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldNumber
                  variant="SMALL"
                  disabled={setupFlow.isLoading}
                  value={eventMapping.purchase ?? ""}
                  onChange={event => {
                    const value = event.target.rawValue;
                    setEventMapping({
                      purchase: value === "" ? null : Number(value)
                    });
                  }}
                  placeholder="123456"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
          </Step1SectionInnerWrapper>
        </StepSection>
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={setupFlow.isLoading}
          isStepCompleted={setupFlow.isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areCJAffiliateFieldsFilledIn
              ? "All fields are required"
              : !areCJAffiliateFieldsUnique
                ? "All fields must be unique"
                : null
          }
          onSave={async data => {
            await setupFlow.configMutation({
              ...data,
              adAccountId,
              accessToken,
              eventMapping
            });
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted: setupFlow.isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={setupFlow.isLoading}
      initial={config.consentMode}
      onSave={data => setupFlow.configMutation(data)}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  return (
    <TransactionIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["id", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { orderAttributeId }
        });
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  return (
    <ProductIdentifier
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      hasRecommendedOption={false}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        await setupFlow.configMutation({
          dataConfig: { productAttributeMapping }
        });
      }}
      descriptionOverride={
        <>
          Please choose the primary product identifier you'd like to use in this
          CJ integration. This should match the primary identifier used in your
          CJ product catalog. If you are uncertain, please verify with the CJ
          support team what product identifier you should use.
        </>
      }
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <FilterTransactions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={orderFilters => setupFlow.configMutation({ orderFilters })}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfigRequired();
  const setupFlow = useSetupFlowDetails();

  return (
    <Subscriptions
      isLoading={setupFlow.isLoading}
      isStepCompleted={setupFlow.isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: data => setupFlow.configMutation(data)
      }}
    />
  );
};
