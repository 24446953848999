import { xor } from "lodash-es";
import { useId, useState } from "react";
import styled, { useTheme } from "styled-components";
import { type PartialDeep } from "type-fest";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";
import { useUpdateLayoutEffect } from "elevar-design-system/src/useUpdateEffect";

import {
  InputFieldMultiSelect,
  type MultiSelectItem
} from "../../components/InputFieldMultiSelect";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { formatTitle } from "../../utils/format";
import { track } from "../../utils/track";
import { type Destination } from "./data";

/* ========================================================================== */

type Config = EventsConnectorConfig[Destination["configKey"]][number];

type MarketGroupSettingsProps = {
  config: Config;
  destination: Destination;
  isLoading: boolean;
  isStepCompleted: boolean;
  saveButtonDisabledTooltipOverride?: string | null;
  onSave: (data: Omit<PartialDeep<Config>, "completedStep">) => void;
};

export const MarketGroupSettings: React.FC<MarketGroupSettingsProps> = ({
  config,
  destination,
  isLoading,
  isStepCompleted,
  saveButtonDisabledTooltipOverride,
  onSave
}) => {
  const { eventsConnectorConfig } = useMyTrackingDetails();

  const isMarketCompatible = destination.name !== "Taboola";

  return (
    <MarketGroupSettingsWrapper>
      {isMarketCompatible &&
      eventsConnectorConfig.globalConfig.marketsEnabled ? (
        <MarketGroupSettingsInner
          config={config}
          destination={destination}
          saveButtonDisabledTooltipOverride={saveButtonDisabledTooltipOverride}
          isLoading={isLoading}
          isStepCompleted={isStepCompleted}
          onSave={onSave}
        />
      ) : (
        <Tooltip
          placement="right"
          text={saveButtonDisabledTooltipOverride ?? ""}
          disabled={!saveButtonDisabledTooltipOverride}
        >
          <span>
            <ButtonPrimary
              variant="SMALL"
              state={
                isLoading
                  ? "LOADING"
                  : saveButtonDisabledTooltipOverride
                    ? "DISABLED"
                    : "IDLE"
              }
              onClick={() => onSave({})}
            >
              {isStepCompleted ? "Save" : "Save & Continue"}
            </ButtonPrimary>
          </span>
        </Tooltip>
      )}
    </MarketGroupSettingsWrapper>
  );
};

const MarketGroupSettingsWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

/* ========================================================================== */

const MarketGroupSettingsInner: React.FC<MarketGroupSettingsProps> = ({
  config,
  destination,
  saveButtonDisabledTooltipOverride,
  isLoading,
  isStepCompleted,
  onSave
}) => {
  const theme = useTheme();
  const radioGroupName = useId();
  const { eventsConnectorConfig } = useMyTrackingDetails();

  const [allMarkets, setAllMarkets] = useState(config.all_markets);
  const [marketGroups, setMarketGroups] = useState(config.market_groups);
  const [labelDraft, setLabelDraft] = useState(config.label ?? "");

  useUpdateLayoutEffect(() => {
    setLabelDraft(config.label ?? "");
  }, [config.label]);

  const trimmedLabel = labelDraft.trim();

  const isDisabled =
    saveButtonDisabledTooltipOverride ||
    (!allMarkets && marketGroups.length === 0);

  const allConfigs = eventsConnectorConfig[destination.configKey];
  const allMarketGroups = eventsConnectorConfig.marketGroups;

  const items = allMarketGroups.map<MultiSelectItem<number>>(group => {
    const isNoMarketIdGroup = group.markets.some(
      m => m.source === "_Required" && m.external_id === "no_market_id"
    );
    const otherConfigsUsingGroup = allConfigs
      .filter(c => c.id !== config.id)
      .filter(c => c.market_groups.includes(group.id))
      .map(c => formatTitle(destination.name, c.label));

    return {
      name: group.markets.map(m => m.name).join(", "),
      value: group.id,
      explainer: isNoMarketIdGroup
        ? "When a Market ID isn't present in an event. This can happen for offline orders (POS orders, etc.), as well as non-Shopify subdomain scenarios (as we can't add this automatically)."
        : otherConfigsUsingGroup.length > 0
          ? marketGroups.includes(group.id)
            ? `Also used by: ${otherConfigsUsingGroup.join(", ")}`
            : `Used by: ${otherConfigsUsingGroup.join(", ")}`
          : null
    };
  });

  return (
    <MarketGroupSettingsInnerWrapper>
      <div>
        <div>
          <div>Markets</div>
          <div>
            Select which markets' data you would like to send to this
            destination:
          </div>
        </div>
        <div>
          <div>
            <div>
              <LabeledRadioText
                groupName={radioGroupName}
                isSelected={allMarkets}
                setIsSelected={() => setAllMarkets(true)}
                text="All markets"
                isDisabled={isLoading}
              />
              <LabeledRadioText
                groupName={radioGroupName}
                isSelected={!allMarkets}
                setIsSelected={() => setAllMarkets(false)}
                text="Specific market(s)"
                isDisabled={isLoading}
              />
            </div>
            {!allMarkets ? (
              <div>
                <InputWrapper
                  labelText="Market Groups"
                  disabled={isLoading}
                  tooltip={{
                    maxWidth: `${theme.gridBase * 41}px`,
                    render: () => (
                      <MarketGroupSettingsTooltipContent>
                        Mirrors the Market Groups you setup in your Shopify
                        source's "GTM & Events" step.
                      </MarketGroupSettingsTooltipContent>
                    )
                  }}
                >
                  <InputFieldMultiSelect
                    variant="SMALL"
                    items={items}
                    selectedItems={items.filter(item =>
                      marketGroups.includes(item.value)
                    )}
                    setSelectedItems={items => {
                      setMarketGroups(items.map(i => i.value));
                    }}
                    placeholder="Select Market Group(s)"
                    disabled={isLoading}
                    maxMenuHeight={`${theme.gridBase * 50}px`}
                  />
                </InputWrapper>
              </div>
            ) : null}
          </div>
          <div>
            {xor(marketGroups, config.market_groups).length > 0 ? (
              <div>
                🔶 We recommend updating destination labels to reflect the
                selected market(s), e.g. "US + UK":
              </div>
            ) : null}
            <div>
              <InputWrapper
                labelText="Destination Label"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 51.5}px`,
                  render: () => (
                    <MarketGroupSettingsTooltipContent>
                      <p>
                        Labels appear next to destination names. This helps to
                        identify your destination across our app, especially
                        when you have multiple instances of the same
                        destination.
                      </p>
                    </MarketGroupSettingsTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={labelDraft}
                  onChange={event => {
                    setLabelDraft(event.target.value.slice(0, 20));
                  }}
                  placeholder="US + UK"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
              <div>{trimmedLabel.length}/20 characters</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Tooltip
          placement="right"
          text={
            saveButtonDisabledTooltipOverride ??
            'Market Groups must be provided if "All Markets" is not selected'
          }
          disabled={!isDisabled}
        >
          <span>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : isDisabled ? "DISABLED" : "IDLE"}
              onClick={() => {
                const label = trimmedLabel === "" ? null : trimmedLabel;

                onSave({
                  all_markets: allMarkets,
                  market_groups: allMarkets ? [] : marketGroups,
                  label
                });

                if (!allMarkets) {
                  track.destinationSpecificMarketsSelect({
                    destinationName: destination.name,
                    destinationLabel: label,
                    marketGroupCount: marketGroups.length
                  });
                }
              }}
            >
              {isStepCompleted ? "Save" : "Save & Continue"}
            </ButtonPrimary>
          </span>
        </Tooltip>
      </div>
    </MarketGroupSettingsInnerWrapper>
  );
};

const MarketGroupSettingsInnerWrapper = styled.div`
  > div:first-child {
    > div:first-child {
      margin-bottom: ${props => props.theme.gridBase * 2}px;

      > div:first-child {
        ${normalBodyStyles};
        font-weight: 600;
        margin-bottom: ${props => props.theme.gridBase}px;
      }

      > div:last-child {
        ${normalBodyStyles};
        color: ${props => props.theme.palette.grey2};
      }
    }

    > div:last-child {
      > div:first-child {
        max-width: ${props => props.theme.gridBase * 50}px;
        margin-bottom: ${props => props.theme.gridBase * 3}px;

        > div:first-child {
          margin-bottom: ${props => props.theme.gridBase * 2}px;
        }
      }

      > div:last-child {
        > div:not(:last-child) {
          ${normalBodyStyles};
          color: ${props => props.theme.palette.grey2};
          margin-bottom: ${props => props.theme.gridBase * 2}px;
        }

        > div:last-child {
          display: flex;
          flex-direction: column;

          max-width: ${props => props.theme.gridBase * 50}px;

          > div:last-child {
            align-self: end;
            ${smallTextStyles};
            color: ${props => props.theme.palette.grey3};
            margin-top: ${props => props.theme.gridBase * 0.5}px;
          }
        }
      }
    }
  }

  > div:last-child {
    margin-top: ${props => props.theme.gridBase * 3}px;
    border-top: 1px solid ${props => props.theme.palette.grey6};
    padding-top: ${props => props.theme.gridBase * 3}px;
  }
`;

const MarketGroupSettingsTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;
