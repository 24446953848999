import {
  camelCase,
  capitalize,
  groupBy,
  isEqual,
  sortBy,
  uniq
} from "lodash-es";
import { useState } from "react";
import styled, { type DefaultTheme, useTheme } from "styled-components";
import { type ConditionalKeys } from "type-fest";

import {
  type DerivedEventKey,
  type EventKey as BaseEventKey,
  type EventsConnectorConfig
} from "elevar-common-ts/src/apiTypes";
import {
  typedFromEntries,
  unsafeTypedEntries
} from "elevar-common-ts/src/utils";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { LabeledCheckBoxMulti } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxMulti";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { scrollbarHoriztonalMixin } from "elevar-design-system/src/scrollbar";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { BestPracticesSelection } from "../../components/BestPracticesSelection";
import { EventTable, type EventTableProps } from "../../components/EventTable";
import { Modal } from "../../components/Modal";
import { useGlobalError } from "../../context/GlobalError";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { formatEventName } from "../../utils/format";
import { useWebsiteId } from "../../utils/idHooks";
import { track } from "../../utils/track";
import { type Destination } from "./data";

/* ========================================================================== */

type EventKey = BaseEventKey | DerivedEventKey;

type EventsDestination = Extract<
  Destination,
  {
    configKey: ConditionalKeys<
      EventsConnectorConfig,
      Extract<
        EventsConnectorConfig[Destination["configKey"]],
        | Array<{ enabledEvents: Record<string, boolean> }>
        | Array<{ enabledWebEvents: Record<string, boolean> }>
      >
    >;
  }
>;

type EventState<
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
> = {
  enabledEvents: Record<TServer, boolean>;
  enabledWebEvents: Record<TWeb, boolean>;
  webhookOverrides: Record<TOverrides, boolean>;
};

const getRecommendedEventStateInternal = <
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
>(
  args: EventState<TServer, TWeb, TOverrides>
): EventState<TServer, TWeb, TOverrides> => {
  return {
    enabledEvents: args.enabledEvents,
    enabledWebEvents: args.enabledWebEvents,
    webhookOverrides: typedFromEntries(
      unsafeTypedEntries(args.webhookOverrides).map(([key, value]) =>
        key === "addPaymentInfo" ||
        key === "addShippingInfo" ||
        key === "beginCheckout" ||
        key === "purchase"
          ? [key, true]
          : [key, value]
      )
    )
  };
};

type GetConfigObjectKeysFromDestination<
  TDestination extends EventsDestination,
  TObject extends "enabledEvents" | "enabledWebEvents" | "webhookOverrides"
> = EventsConnectorConfig[TDestination["configKey"]][number] extends {
  [K in TObject]: Record<infer R extends EventKey, unknown>;
}
  ? R
  : never;

type GetEventStateFromDestination<T extends Destination> = EventState<
  GetConfigObjectKeysFromDestination<T, "enabledEvents">,
  GetConfigObjectKeysFromDestination<T, "enabledWebEvents">,
  GetConfigObjectKeysFromDestination<T, "webhookOverrides">
>;

export const getRecommendedEventState = <T extends EventsDestination>(
  args: { destination: T } & GetEventStateFromDestination<T>
): GetEventStateFromDestination<T> => {
  return getRecommendedEventStateInternal(args);
};

/* ========================================================================== */

type StringEvent =
  | "Klaviyo"
  | "Voluum"
  | "Taboola"
  | "Iterable"
  | "Mixpanel"
  | "X (Twitter)";
type NullableStringEvent = "Outbrain";
type StringStringEvent = "Microsoft Advertising";
type ArrayStringEvent = "Google Ads";

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

type EventDestinationDetails<
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
> = {
  eventState: EventState<TServer, TWeb, TOverrides>;
  setEventState: SetState<EventState<TServer, TWeb, TOverrides>>;
} & (
  | {
      destinationName: Extract<EventsDestination["name"], StringEvent>;
      eventMapping: Record<NoInfer<TServer | TWeb>, string>;
      setEventMapping: SetState<Record<NoInfer<TServer | TWeb>, string>>;
    }
  | {
      destinationName: Extract<EventsDestination["name"], NullableStringEvent>;
      eventMapping: Record<NoInfer<TServer | TWeb>, string | null>;
      setEventMapping: SetState<Record<NoInfer<TServer | TWeb>, string | null>>;
    }
  | {
      destinationName: Extract<EventsDestination["name"], StringStringEvent>;
      eventMapping: Record<NoInfer<TServer | TWeb>, string>;
      setEventMapping: SetState<Record<NoInfer<TServer | TWeb>, string>>;
      eventCategories: Record<NoInfer<TServer | TWeb>, string>;
      setEventCategories: SetState<Record<NoInfer<TServer | TWeb>, string>>;
    }
  | {
      destinationName: Extract<EventsDestination["name"], ArrayStringEvent>;
      conversionNameArrays: Record<NoInfer<TServer | TWeb>, Array<string>>;
      setConversionNameArrays: SetState<
        Record<NoInfer<TServer | TWeb>, Array<string>>
      >;
    }
  | {
      destinationName: Exclude<
        EventsDestination["name"],
        StringEvent | NullableStringEvent | StringStringEvent | ArrayStringEvent
      >;
    }
);

type EventDataItem<T extends EventKey> = readonly [
  T,
  { readonly server: boolean | null; readonly web: boolean | null }
];

type MutualExclusivityLevel = "NONE" | "EVENT" | "TYPE";

const eventOrder: Array<EventKey> = [
  "pageView",
  "viewItemList",
  "selectItem",
  "viewItem",
  "addToCart",
  "viewCart",
  "beginCheckout",
  "addShippingInfo",
  "addPaymentInfo",
  "purchase",
  "newCustomerPurchase",
  "returningCustomerPurchase",
  "channelLiftStudy",
  "completePayment",
  "subscriptionPurchase",
  "removeFromCart",
  "viewSearchResults",
  "signUp",
  "login",
  "refund",
  "subscribe",
  "homeView"
];

type EventDestinationTableProps<
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
> = {
  isLoading: boolean;
  isStepCompleted: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  mutualExclusivityLevel: MutualExclusivityLevel;
  showBreakdownByDefault?: boolean;
  details: EventDestinationDetails<TServer, TWeb, TOverrides>;
  recommended: Omit<
    EventState<NoInfer<TServer>, NoInfer<TWeb>, NoInfer<TOverrides>>,
    "webhookOverrides"
  >;
  onSave: () => void;
};

export const EventDestinationTable = <
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
>({
  isLoading,
  isStepCompleted,
  shopifyOAuthScopes,
  mutualExclusivityLevel,
  showBreakdownByDefault = false,
  details,
  recommended,
  onSave
}: EventDestinationTableProps<
  TServer,
  TWeb,
  TOverrides
>): ReturnType<React.FC> => {
  const theme = useTheme();

  const [isCheckoutWebhookModalShown, setIsCheckoutWebhookModalShown] =
    useState(false);
  const [isPurchaseWebhookModalShown, setIsPurchaseWebhookModalShown] =
    useState(false);
  const [isWebOnlyConfirmModalShown, setIsWebOnlyConfirmModalShown] =
    useState(false);

  const serverEntries = unsafeTypedEntries(
    details.eventState.enabledEvents
  ).map(([k, v]) => [k, { type: "SERVER", enabled: v }] as const);

  const webEntries = unsafeTypedEntries(
    details.eventState.enabledWebEvents
  ).map(([k, v]) => [k, { type: "WEB", enabled: v }] as const);

  const groupedEntries = unsafeTypedEntries(
    groupBy([...serverEntries, ...webEntries], ([k]) => k)
  ).map(([k, v]) => [k as TServer | TWeb, v.map(([_, v]) => v)] as const);

  const eventData = groupedEntries.map(
    ([k, v]) =>
      [
        k,
        {
          server: v.find(i => i.type === "SERVER")?.enabled ?? null,
          web: v.find(i => i.type === "WEB")?.enabled ?? null
        }
      ] as const
  );

  const sortedEventData = sortBy(
    eventData,
    eventOrder.map<([key]: EventDataItem<TServer | TWeb>) => boolean>(
      e1Key =>
        ([e2Key]) =>
          e2Key === e1Key
    )
  ).reverse();

  const recommendedEventState = getRecommendedEventStateInternal({
    ...recommended,
    webhookOverrides: details.eventState.webhookOverrides
  });

  const showOverrides = sortedEventData.some(([_k, v]) => v.server !== null);

  const addPaymentInfoOverride =
    showOverrides && "addPaymentInfo" in details.eventState.webhookOverrides
      ? (details.eventState.webhookOverrides.addPaymentInfo as boolean)
      : null;
  const addShippingInfoOverride =
    showOverrides && "addShippingInfo" in details.eventState.webhookOverrides
      ? (details.eventState.webhookOverrides.addShippingInfo as boolean)
      : null;
  const beginCheckoutOverride =
    showOverrides && "beginCheckout" in details.eventState.webhookOverrides
      ? (details.eventState.webhookOverrides.beginCheckout as boolean)
      : null;
  const purchaseOverride =
    showOverrides && "purchase" in details.eventState.webhookOverrides
      ? (details.eventState.webhookOverrides.purchase as boolean)
      : null;

  const areServerEventsEnabled = eventData.some(([_k, v]) => v.server);
  const areWebEventsEnabled = eventData.some(([_k, v]) => v.web);
  const areAnyEventsEnabled = areServerEventsEnabled || areWebEventsEnabled;

  const areRequiredCtidFieldsValid =
    details.destinationName !== "Google Ads" ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server ||
        details.conversionNameArrays[key].every(ctid => ctid !== "")
    );
  const areRequiredCtidFieldsWithinLimit =
    details.destinationName !== "Google Ads" ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server || details.conversionNameArrays[key].length <= 3
    );
  const areRequiredCtidFieldsUnique =
    details.destinationName !== "Google Ads" ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server ||
        uniq(details.conversionNameArrays[key]).length ===
          details.conversionNameArrays[key].length
    );

  const areRequiredEventNameFieldsFilledIn =
    (details.destinationName !== "Klaviyo" &&
      details.destinationName !== "Outbrain" &&
      details.destinationName !== "Taboola" &&
      details.destinationName !== "Iterable" &&
      details.destinationName !== "Mixpanel" &&
      details.destinationName !== "X (Twitter)") ||
    sortedEventData.every(
      ([key, value]) => !value.server || details.eventMapping[key]
    );

  const areRequiredEtValueFieldsFilledIn =
    details.destinationName !== "Voluum" ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server || details.eventMapping[key] || key === "purchase"
    );

  const areRequiredActionAndCategoryFieldsFilledIn =
    details.destinationName !== "Microsoft Advertising" ||
    sortedEventData.every(
      ([key, value]) =>
        !value.server ||
        (details.eventMapping[key] && details.eventCategories[key])
    );

  const canWebOnlyConfirmModalShow =
    !isStepCompleted &&
    Object.values(recommended.enabledEvents).some(enabled => enabled) &&
    !areServerEventsEnabled;

  return (
    <>
      <EventDestinationTableWrapper>
        <BestPracticesSelection
          isDisabled={isLoading}
          showBreakdownByDefault={showBreakdownByDefault}
          initialIsBestPracticesSelected={isEqual(
            details.eventState,
            recommendedEventState
          )}
          onSelect={({ isBestPracticesSelected }) => {
            if (isBestPracticesSelected) {
              details.setEventState(recommendedEventState);
              track.destinationEventsRecommendedRadioSelect({
                destinationName: details.destinationName,
                hasStepBeenPreviouslyCompleted: isStepCompleted
              });
            } else {
              track.destinationEventsCustomizeRadioSelect({
                destinationName: details.destinationName,
                hasStepBeenPreviouslyCompleted: isStepCompleted
              });
            }
          }}
        >
          {({ isBestPracticesSelected }) => (
            <>
              <EventBreakdownTable
                isLoading={isLoading}
                shopifyOAuthScopes={shopifyOAuthScopes}
                isBestPracticesSelected={isBestPracticesSelected}
                details={details}
                mutualExclusivityLevel={mutualExclusivityLevel}
                eventData={sortedEventData}
              />
              {addPaymentInfoOverride !== null ||
              addShippingInfoOverride !== null ||
              beginCheckoutOverride !== null ||
              purchaseOverride !== null ? (
                <EventTrackingCustomizationWrapper>
                  <div>Event Tracking Customization</div>
                  <div>
                    {addPaymentInfoOverride !== null ||
                    addShippingInfoOverride !== null ||
                    beginCheckoutOverride !== null ? (
                      <LabeledCheckBoxMulti
                        variant="NORMAL"
                        isChecked={
                          (addPaymentInfoOverride ?? false) ||
                          (addShippingInfoOverride ?? false) ||
                          (beginCheckoutOverride ?? false)
                        }
                        setIsChecked={isChecked => {
                          if (isChecked) {
                            details.setEventState({
                              ...details.eventState,
                              webhookOverrides: {
                                ...details.eventState.webhookOverrides,
                                ...(addPaymentInfoOverride !== null
                                  ? { addPaymentInfo: true }
                                  : {}),
                                ...(addShippingInfoOverride !== null
                                  ? { addShippingInfo: true }
                                  : {}),
                                ...(beginCheckoutOverride !== null
                                  ? { beginCheckout: true }
                                  : {})
                              }
                            });
                          } else {
                            setIsCheckoutWebhookModalShown(true);
                          }
                        }}
                        text="Trigger checkout funnel events from checkout actions"
                        isDisabled={isLoading || isBestPracticesSelected}
                        tooltip={{
                          maxWidth: `${theme.gridBase * 39}px`,
                          render: () => (
                            <EventDestinationTableTooltipContent>
                              This will use checkout create/update actions as
                              the trigger for checkout funnel events, rather
                              than checkout page loads.
                            </EventDestinationTableTooltipContent>
                          )
                        }}
                      />
                    ) : null}
                    {purchaseOverride !== null ? (
                      <LabeledCheckBoxMulti
                        variant="NORMAL"
                        isChecked={purchaseOverride}
                        setIsChecked={isChecked => {
                          if (isChecked) {
                            details.setEventState({
                              ...details.eventState,
                              webhookOverrides: {
                                ...details.eventState.webhookOverrides,
                                purchase: true
                              }
                            });
                          } else {
                            setIsPurchaseWebhookModalShown(true);
                          }
                        }}
                        text="Trigger purchase events from order creation"
                        isDisabled={isLoading || isBestPracticesSelected}
                        tooltip={{
                          maxWidth: `${theme.gridBase * 47}px`,
                          render: () => (
                            <EventDestinationTableTooltipContent>
                              This will use order creation as the trigger for
                              purchase events, rather than the "Thank You" page
                              load. We recommend using this for better tracking.
                            </EventDestinationTableTooltipContent>
                          )
                        }}
                      />
                    ) : null}
                  </div>
                </EventTrackingCustomizationWrapper>
              ) : null}
            </>
          )}
        </BestPracticesSelection>
      </EventDestinationTableWrapper>
      {isStepCompleted &&
      Object.values(details.eventState.enabledWebEvents).length > 0 ? (
        <EventDestinationTableNotice>
          Note: For any changes made to the "Web" column to take effect after
          initial setup, please revisit the "Web Container Setup" step.
        </EventDestinationTableNotice>
      ) : null}
      <Tooltip
        placement="right"
        text={
          !areAnyEventsEnabled
            ? "At least 1 event must be enabled"
            : !areRequiredCtidFieldsValid
              ? "CTID fields cannot include leading or trailing commas"
              : !areRequiredCtidFieldsWithinLimit
                ? "CTID fields can only contain a maximum of 3 values"
                : !areRequiredCtidFieldsUnique
                  ? "CTID values must be unique per field"
                  : !areRequiredEventNameFieldsFilledIn
                    ? "All enabled events must have an event name provided"
                    : !areRequiredEtValueFieldsFilledIn
                      ? "All enabled events (except purchase) must have an et value provided"
                      : !areRequiredActionAndCategoryFieldsFilledIn
                        ? "All enabled events must have both an action and category provided"
                        : ""
        }
        disabled={
          areAnyEventsEnabled &&
          areRequiredCtidFieldsValid &&
          areRequiredCtidFieldsWithinLimit &&
          areRequiredCtidFieldsUnique &&
          areRequiredEventNameFieldsFilledIn &&
          areRequiredEtValueFieldsFilledIn &&
          areRequiredActionAndCategoryFieldsFilledIn
        }
      >
        <EventDestinationTableTooltipInner>
          <ButtonPrimary
            variant="SMALL"
            state={
              isLoading
                ? "LOADING"
                : !areAnyEventsEnabled ||
                    !areRequiredCtidFieldsValid ||
                    !areRequiredCtidFieldsWithinLimit ||
                    !areRequiredCtidFieldsUnique ||
                    !areRequiredEventNameFieldsFilledIn ||
                    !areRequiredEtValueFieldsFilledIn ||
                    !areRequiredActionAndCategoryFieldsFilledIn
                  ? "DISABLED"
                  : "IDLE"
            }
            onClick={() => {
              if (canWebOnlyConfirmModalShow) {
                setIsWebOnlyConfirmModalShown(true);
              } else {
                onSave();
              }
            }}
          >
            {isStepCompleted ? "Save" : "Save & Continue"}
          </ButtonPrimary>
        </EventDestinationTableTooltipInner>
      </Tooltip>
      {addPaymentInfoOverride !== null ||
      addShippingInfoOverride !== null ||
      beginCheckoutOverride !== null ? (
        <Modal
          isVisible={isCheckoutWebhookModalShown}
          onClose={() => setIsCheckoutWebhookModalShown(false)}
        >
          <WebhookModalContents>
            <ModalTitle>Deactivate action-based trigger</ModalTitle>
            <ModalBody>
              By continuing, you will use checkout page loads as the trigger for
              checkout funnel events. This can cause lower data accuracy.
            </ModalBody>
            <ModalButtons>
              <ButtonSecondary
                variant="SMALL"
                onClick={() => setIsCheckoutWebhookModalShown(false)}
              >
                Go Back
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                onClick={() => {
                  setIsCheckoutWebhookModalShown(false);
                  details.setEventState({
                    ...details.eventState,
                    webhookOverrides: {
                      ...details.eventState.webhookOverrides,
                      ...(addPaymentInfoOverride !== null
                        ? { addPaymentInfo: false }
                        : {}),
                      ...(addShippingInfoOverride !== null
                        ? { addShippingInfo: false }
                        : {}),
                      ...(beginCheckoutOverride !== null
                        ? { beginCheckout: false }
                        : {})
                    }
                  });
                }}
              >
                Deactivate
              </ButtonPrimary>
            </ModalButtons>
          </WebhookModalContents>
        </Modal>
      ) : null}
      {purchaseOverride !== null ? (
        <Modal
          isVisible={isPurchaseWebhookModalShown}
          onClose={() => setIsPurchaseWebhookModalShown(false)}
        >
          <WebhookModalContents>
            <ModalTitle>Deactivate order creation trigger</ModalTitle>
            <ModalBody>
              By continuing, you will use the "Thank You" page load as the
              trigger for purchase events. This can cause lower data accuracy.
            </ModalBody>
            <ModalButtons>
              <ButtonSecondary
                variant="SMALL"
                onClick={() => setIsPurchaseWebhookModalShown(false)}
              >
                Go Back
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                onClick={() => {
                  setIsPurchaseWebhookModalShown(false);
                  details.setEventState({
                    ...details.eventState,
                    webhookOverrides: {
                      ...details.eventState.webhookOverrides,
                      purchase: false
                    }
                  });
                }}
              >
                Deactivate
              </ButtonPrimary>
            </ModalButtons>
          </WebhookModalContents>
        </Modal>
      ) : null}
      {canWebOnlyConfirmModalShow ? (
        <Modal
          isVisible={isWebOnlyConfirmModalShown}
          onClose={() => setIsWebOnlyConfirmModalShown(false)}
        >
          <WebOnlyConfirmModalContents>
            <ModalTitle>Heads Up</ModalTitle>
            <ModalBody>
              We noticed you selected to send web events only. We recommend
              selecting server events to get 100% conversion accuracy. How would
              you like to continue?
            </ModalBody>
            <ModalButtons>
              <ButtonSecondary
                variant="SMALL"
                onClick={() => {
                  onSave();
                  setIsWebOnlyConfirmModalShown(false);
                }}
              >
                Keep Web Events Only
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                onClick={() => setIsWebOnlyConfirmModalShown(false)}
              >
                Edit Events
              </ButtonPrimary>
            </ModalButtons>
          </WebOnlyConfirmModalContents>
        </Modal>
      ) : null}
    </>
  );
};

const EventDestinationTableWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const EventTrackingCustomizationWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${subheadingStyles};
    color: ${props => props.theme.palette.grey3};
    padding-left: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  }
`;

const EventDestinationTableTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const EventDestinationTableNotice = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

const EventDestinationTableTooltipInner = styled.span`
  display: inline-block;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

const WebhookModalContents = styled.div`
  width: ${props => props.theme.gridBase * 40}px;
  position: relative;
`;

const WebOnlyConfirmModalContents = styled.div`
  width: ${props => props.theme.gridBase * 50}px;
  position: relative;
`;

/* ========================================================================== */

const getEventTooltip = (
  theme: DefaultTheme,
  marketsEnabled: boolean,
  destinationName: EventsDestination["name"],
  key: EventKey
) => {
  switch (key) {
    case "addContactInfo":
      return {
        maxWidth: theme.gridBase * 37.5,
        content: () => (
          <>
            Triggered when a user reaches the contact step in their checkout
            process.
          </>
        )
      };
    case "addPaymentInfo":
      return {
        maxWidth: theme.gridBase * 37.5,
        content: () => (
          <>
            Triggered when a user reaches the payment step in their checkout
            process.
          </>
        )
      };
    case "addShippingInfo":
      return {
        maxWidth: theme.gridBase * 37.5,
        content: () => (
          <>
            Triggered when a user reaches the shipping step in their checkout
            process.
          </>
        )
      };
    case "addToCart":
      return {
        maxWidth: theme.gridBase * 32,
        content: () => (
          <>
            Triggered on all add to cart event actions from your online store.
          </>
        )
      };
    case "beginCheckout":
      return {
        maxWidth: theme.gridBase * 28.5,
        content: () => <>Triggered when a user starts their checkout process.</>
      };
    case "channelLiftStudy":
      return {
        maxWidth: theme.gridBase * 59,
        content: () => (
          <>
            <p>
              This sends an event titled “ChannelPurchase” to Facebook, which
              Facebook can use to measure the incremental halo effect that
              Facebook has on an advertiser's other paid & organic channels.
            </p>
            <p>Requires that the corresponding purchase event is enabled.</p>
          </>
        )
      };
    case "completePayment":
      return {
        maxWidth: theme.gridBase * 36,
        content: () => (
          <>
            Triggered on transaction events from all channels connected to your
            Shop.
          </>
        )
      };
    case "homeView":
      return {
        maxWidth: theme.gridBase * 36,
        content: () => <>Triggered on home views.</>
      };
    case "login":
      return {
        maxWidth: theme.gridBase * 33,
        content: () => (
          <>
            Triggered when a user logs into their account on your online store.
          </>
        )
      };
    case "newCustomerPurchase":
      return {
        maxWidth: theme.gridBase * 54.5,
        content: () => (
          <>
            <p>Triggered on transaction events for new customers.</p>
            <p>Requires that the corresponding purchase event is enabled.</p>
          </>
        )
      };
    case "pageView":
      return {
        maxWidth: theme.gridBase * 27,
        content: () => <>Triggered on all pageviews from your online store.</>
      };
    case "purchase":
      return {
        maxWidth: theme.gridBase * 35.5,
        content: () => (
          <>
            Triggered on transaction events from all channels connected to your
            Shop.
          </>
        )
      };
    case "refund":
      return {
        maxWidth: theme.gridBase * 56.5,
        content: () => (
          <>
            <p>
              Triggered when a customer returns items they've purchased and you
              have to refund a customer's payment.
            </p>
            {destinationName === "GA4" ? (
              <p>Does not work for GA4 when consent mode is enabled.</p>
            ) : null}
            {marketsEnabled ? (
              <p>
                To send this event when Shopify Markets is enabled, you must
                select "All Markets" or include the "No Market ID" option when
                specifying Market Groups in this destinations Settings step, as
                refund events come in without a Market ID.
              </p>
            ) : null}
          </>
        )
      };
    case "removeFromCart":
      return {
        maxWidth: theme.gridBase * 35,
        content: () => (
          <>
            Triggered on all remove from cart event actions from your online
            store.
          </>
        )
      };
    case "returningCustomerPurchase":
      return {
        maxWidth: theme.gridBase * 54.5,
        content: () => (
          <>
            <p>Triggered on transaction events for returning customers.</p>
            <p>Requires that the corresponding purchase event is enabled.</p>
          </>
        )
      };
    case "signUp":
      return {
        maxWidth: theme.gridBase * 33,
        content: () => (
          <>
            Triggered when a user signs up for an account on your online store.
          </>
        )
      };
    case "selectItem":
      return {
        maxWidth: theme.gridBase * 34,
        content: () => <>Triggers when an item is selected.</>
      };
    case "subscribe":
      return {
        maxWidth: theme.gridBase * 35,
        content: () => (
          <>
            Triggered when a user subscribes to an email list from your online
            store.
          </>
        )
      };
    case "subscriptionPurchase":
      return {
        maxWidth: theme.gridBase * 63,
        content: () => (
          <>
            <p>
              Triggered when a shopper purchases a first time subscription order
              on a unified Shopify Checkout.
            </p>
            <p>
              Requires the purchase to include a product with a selling plan
              name or the "Subscription First Order" tag to be added prior to
              order creation.
            </p>
            <p>Requires that the corresponding purchase event is enabled.</p>
          </>
        )
      };
    case "viewCart":
      return {
        maxWidth: theme.gridBase * 32,
        content: () => (
          <>Triggered when a user visits the cart page on your online store.</>
        )
      };
    case "viewItem":
      return {
        maxWidth: theme.gridBase * 28,
        content: () => (
          <>Triggered on product detail views from your online store.</>
        )
      };
    case "viewItemList":
      return {
        maxWidth: theme.gridBase * 28,
        content: () => (
          <>Triggered on collection views from your online store.</>
        )
      };
    case "viewSearchResults":
      return {
        maxWidth: theme.gridBase * 36,
        content: () => (
          <>
            Triggered when a user views a search results page on your online
            store.
          </>
        )
      };
  }
};

type Column1Metadata = {
  name: string | null;
  getPlaceholder: (key: string, name: string) => string;
};

const getColumn1Metadata = (name: Destination["name"]): Column1Metadata => {
  switch (name) {
    case "Google Ads":
      return {
        name: "Conversion Action\xa0CTID",
        getPlaceholder: () => "We will create this for you"
      };
    case "Klaviyo":
      return {
        name: "Event\xa0Name",
        getPlaceholder: key => {
          if (key === "addToCart") return "Added to Cart - Elevar SS";
          if (key === "beginCheckout") return "Begin Checkout - Elevar SS";
          if (key === "pageView") return "Page View - Elevar SS";
          if (key === "purchase") return "Purchase - Elevar SS";
          if (key === "viewItem") return "Viewed Product - Elevar SS";
          if (key === "viewItemList") return "Category View - Elevar SS";
          else return "";
        }
      };
    case "Iterable":
      return {
        name: "Event\xa0Name",
        getPlaceholder: key => {
          if (key === "addToCart") return "Added to Cart - Elevar SS";
          if (key === "beginCheckout") return "Begin Checkout - Elevar SS";
          if (key === "pageView") return "Page View - Elevar SS";
          if (key === "purchase") return "Purchase - Elevar SS";
          if (key === "viewItem") return "Viewed Product - Elevar SS";
          if (key === "viewItemList") return "Category View - Elevar SS";
          else return "";
        }
      };
    case "Outbrain":
      return {
        name: "Event\xa0Name",
        getPlaceholder: name => `e.g. ${camelCase(name)}`
      };
    case "Mixpanel":
      return {
        name: "Event\xa0Name",
        getPlaceholder: name => `e.g. ${camelCase(name)}`
      };
    case "Taboola":
      return {
        name: "Conversion Event\xa0Name",
        getPlaceholder: name => `e.g. ${camelCase(name)}`
      };
    case "Voluum":
      return {
        name: "ET\xa0Value",
        getPlaceholder: (key, name) =>
          key === "purchase"
            ? `e.g. ${name.toLowerCase()} - optional`
            : `e.g. ${camelCase(name)}`
      };
    case "Microsoft Advertising":
      return {
        name: "Action",
        getPlaceholder: name => `e.g. ${name.toLowerCase()}`
      };
    case "X (Twitter)":
      return {
        name: "Event\xa0ID",
        getPlaceholder: name => `e.g. ${camelCase(name)}`
      };
    default:
      return {
        name: null,
        getPlaceholder: () => ""
      };
  }
};

const getInputTextColumn1Tooltip = (
  theme: DefaultTheme,
  name: Destination["name"]
) => {
  switch (name) {
    case "Voluum":
      return {
        maxWidth: theme.gridBase * 42,
        content: () => (
          <>
            <p>
              The value of the <span>et</span> parameter as set in your custom
              conversion event within Voluum. You can leave the <span>et</span>{" "}
              value blank for the purchase event only - if you are wanting the
              purchase to show up as a "conversion" in Voluum.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-voluum-et-value"
              text="Where do I find this?"
            />
          </>
        )
      };
    case "Google Ads":
      return {
        maxWidth: theme.gridBase * 59,
        content: () => (
          <>
            <p>
              This is a unique "ctId" parameter in the URL of your conversion
              action details. We'll create the conversion actions for you if you
              leave this blank. Alternatively, you can create your own and input
              the associated CTID (or multiple CTID's per event – separated by
              commas). Note, this is not the Conversion Label or Conversion ID.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-google-ads-ctld-value"
              text="Where do I find this?"
            />
          </>
        )
      };
    case "Klaviyo":
      return {
        maxWidth: theme.gridBase * 45.5,
        content: () => (
          <p>
            The event name that you want to send to Klaviyo. To do a split test
            with your flows, we recommend including 'Elevar SS' in your event
            name.
          </p>
        )
      };
    case "Outbrain":
      return {
        maxWidth: theme.gridBase * 30,
        content: () => (
          <>
            <p>Event names as you have them in your Outbrain settings.</p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-conversion-event-name-in-outbrain"
              text="How to find this"
            />
          </>
        )
      };
    case "Taboola":
      return {
        maxWidth: theme.gridBase * 35.5,
        content: () => (
          <>
            <p>
              Conversion event names as you have them under Tracking &gt;
              Conversions.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-conversion-event-name-in-taboola"
              text="How to find this"
            />
          </>
        )
      };
    case "Iterable":
      return {
        maxWidth: theme.gridBase * 45.5,
        content: () => (
          <p>
            The event name that you want to send to Iterable. To do a split test
            with your flows, we recommend including 'Elevar SS' in your event
            name.
          </p>
        )
      };
    case "Microsoft Advertising":
      return {
        maxWidth: theme.gridBase * 36,
        content: () => (
          <>
            <p>
              Event action names as you have them in your Microsoft Advertising
              settings.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-bingmicrosoft-event-action-and-category"
              text="How to find this"
            />
          </>
        )
      };
    case "Mixpanel":
      return {
        maxWidth: theme.gridBase * 45.25,
        content: () => (
          <p>
            These are the event names as you'll see them in Mixpanel. If your
            previous tracking used different event names, please change the
            below names to match. This will ensure continuity between your
            historical and future data.
          </p>
        )
      };
    case "X (Twitter)":
      return {
        maxWidth: theme.gridBase * 29.5,
        content: () => (
          <>
            <p>Event IDs as you have them in your X (Twitter) Ads account.</p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-or-create-conversion-events-for-the-x-conversion-api"
              text="How to find this"
            />
          </>
        )
      };
    default:
      return null;
  }
};

const getInputTextColumn2Tooltip = (
  theme: DefaultTheme,
  name: Destination["name"]
) => {
  switch (name) {
    case "Microsoft Advertising":
      return {
        maxWidth: theme.gridBase * 38,
        content: () => (
          <>
            <p>
              Event category names as you have them in your Microsoft
              Advertising settings.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-bingmicrosoft-event-action-and-category"
              text="How to find this"
            />
          </>
        )
      };
    default:
      return null;
  }
};

/* ========================================================================== */

type EventBreakdownTableProps<
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
> = {
  isLoading: boolean;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  isBestPracticesSelected: boolean;
  details: EventDestinationDetails<TServer, TWeb, TOverrides>;
  mutualExclusivityLevel: MutualExclusivityLevel;
  eventData: Array<EventDataItem<TServer | TWeb>>;
};

const EventBreakdownTable = <
  TServer extends EventKey,
  TWeb extends EventKey,
  TOverrides extends EventKey
>({
  isLoading,
  shopifyOAuthScopes,
  isBestPracticesSelected,
  details,
  mutualExclusivityLevel,
  eventData
}: EventBreakdownTableProps<
  TServer,
  TWeb,
  TOverrides
>): ReturnType<React.FC> => {
  const theme = useTheme();
  const websiteId = useWebsiteId();
  const { globalErrorDispatch } = useGlobalError();
  const { eventsConnectorConfig } = useMyTrackingDetails();

  const { destinationName } = details;
  const { marketsEnabled } = eventsConnectorConfig.globalConfig;

  type K = (typeof eventData)[number][0];
  type V = (typeof eventData)[number][1];

  const rows: EventTableProps<K, V>["rows"] = eventData.map(([key, value]) => ({
    key,
    name: formatEventName(key),
    tooltip: getEventTooltip(theme, marketsEnabled, destinationName, key),
    context: value
  }));

  const column1Metadata = getColumn1Metadata(destinationName);

  const inputTextColumn1Name = column1Metadata.name;

  const inputTextColumn2Name =
    destinationName === "Microsoft Advertising" ? "Category" : null;

  const enabled: Record<"server" | "web", Array<EventKey>> = {
    server: eventData.filter(([_k, v]) => v.server).map(([k, _v]) => k),
    web: eventData.filter(([_k, v]) => v.web).map(([k, _v]) => k)
  };

  const readCustomerShopifyScopeRequiredEventKeys: Array<EventKey> = [
    "newCustomerPurchase",
    "returningCustomerPurchase"
  ];

  const readCustomerShopifyScopeNotPresent =
    shopifyOAuthScopes === null ||
    "redirect_url" in shopifyOAuthScopes ||
    !shopifyOAuthScopes.scopes.includes("read_customers");

  const getRestrictionTooltipText = (
    type: "server" | "web",
    key: K,
    context: V
  ) => {
    if (isBestPracticesSelected) return null;

    const typeDisplayName = capitalize(type);
    const notType = type === "server" ? "web" : "server";

    const customPurchaseEventKeys: Array<EventKey> = [
      ...readCustomerShopifyScopeRequiredEventKeys,
      "channelLiftStudy",
      "subscriptionPurchase"
    ];

    return mutualExclusivityLevel === "TYPE" && enabled[notType].length > 0
      ? "Mutually Exclusive (Type Level)"
      : mutualExclusivityLevel === "EVENT" && context[notType]
        ? "Mutually Exclusive (Event Level)"
        : key === "purchase" &&
            customPurchaseEventKeys.some(k => enabled[type].includes(k))
          ? `Required by Custom Purchase ${typeDisplayName} Events`
          : customPurchaseEventKeys.includes(key) &&
              !enabled[type].includes("purchase")
            ? `Requires "Purchase" ${typeDisplayName} Event`
            : null;
  };

  const columns: EventTableProps<K, V>["columns"] = [
    {
      type: "INPUT_TEXT",
      name: inputTextColumn1Name,
      size:
        destinationName === "Microsoft Advertising"
          ? {
              min: `${theme.gridBase * 19}px`,
              max: `${theme.gridBase * 37.5}px`,
              gap: `${theme.gridBase}px`
            }
          : {
              min: `${theme.gridBase * 19}px`,
              max: `${theme.gridBase * 37.5}px`
            },
      tooltip: getInputTextColumn1Tooltip(theme, destinationName),
      getAccessors: ({ key, name, context }) => {
        return inputTextColumn1Name !== null
          ? {
              value:
                "conversionNameArrays" in details
                  ? details.conversionNameArrays[key].join(",")
                  : "eventMapping" in details
                    ? (details.eventMapping[key] ?? "")
                    : "",
              onChange: event => {
                const value = event.target.value;
                if ("conversionNameArrays" in details) {
                  details.setConversionNameArrays({
                    ...details.conversionNameArrays,
                    [key]:
                      event.target.value === ""
                        ? []
                        : event.target.value
                            .replaceAll(" ", "")
                            .replace(",,", ",")
                            .split(",")
                  });
                } else if ("eventMapping" in details) {
                  if (destinationName === "Outbrain") {
                    details.setEventMapping({
                      ...details.eventMapping,
                      [key]: value === "" ? null : value
                    });
                  } else {
                    details.setEventMapping({
                      ...details.eventMapping,
                      [key]: value
                    });
                  }
                }
              },
              placeholder: column1Metadata.getPlaceholder(key, name),
              isDisabled: isLoading,
              isVisible: context.server ?? false
            }
          : null;
      }
    },
    {
      type: "INPUT_TEXT",
      name: inputTextColumn2Name,
      size:
        destinationName === "Microsoft Advertising"
          ? {
              min: `${theme.gridBase * 19}px`,
              max: `${theme.gridBase * 37.5}px`,
              gap: `${theme.gridBase}px`
            }
          : {
              min: `${theme.gridBase * 19}px`,
              max: `${theme.gridBase * 37.5}px`
            },
      tooltip: getInputTextColumn2Tooltip(theme, destinationName),
      getAccessors: ({ key, context }) => {
        return inputTextColumn2Name !== null
          ? {
              value:
                "eventCategories" in details
                  ? details.eventCategories[key]
                  : "",
              onChange: event => {
                const value = event.target.value;
                if ("eventCategories" in details) {
                  details.setEventCategories({
                    ...details.eventCategories,
                    [key]: value
                  });
                }
              },
              placeholder: "e.g. checkout",
              isDisabled: isLoading,
              isVisible: context.server ?? false
            }
          : null;
      }
    },
    {
      type: "CHECK_BOX",
      name: eventData.some(([_k, v]) => v.server !== null) ? "Server" : null,
      size:
        inputTextColumn1Name !== null || inputTextColumn2Name !== null
          ? {
              min: `${theme.gridBase * 7.5}px`,
              max: `${theme.gridBase * 12.5}px`
            }
          : {
              min: `${theme.gridBase * 6.5}px`,
              max: `${theme.gridBase * 15}px`
            },
      getIntersectTooltip: ({ key, context }) => {
        const text = getRestrictionTooltipText("server", key, context);

        return text
          ? { text }
          : // TODO: Remove when removing Google Ads events
            destinationName === "Google Ads" &&
              (key === "viewItem" || key === "addToCart") &&
              !context.server
            ? { text: "This event is no longer available due rate limits" }
            : null;
      },
      getAccessors: ({ key, context }, intersectTooltip) => {
        return context.server !== null
          ? {
              isChecked: context.server,
              setIsChecked: isChecked => {
                if (
                  isChecked &&
                  readCustomerShopifyScopeRequiredEventKeys.includes(key) &&
                  readCustomerShopifyScopeNotPresent
                ) {
                  globalErrorDispatch({
                    type: "SET_PROACTIVE",
                    payload: { websiteId, code: "SHOPIFY_LACKS_SCOPE" }
                  });
                } else {
                  details.setEventState({
                    ...details.eventState,
                    enabledEvents: {
                      ...details.eventState.enabledEvents,
                      [key]: isChecked
                    }
                  });
                  track.destinationEventsCheckBoxClick({
                    destinationName,
                    eventType: "SERVER",
                    eventName: key,
                    eventEnabled: isChecked
                  });
                }
              },
              isDisabled:
                isLoading ||
                isBestPracticesSelected ||
                intersectTooltip?.text !== undefined ||
                // TODO: Remove when removing Google Ads events
                (destinationName === "Google Ads" &&
                  (key === "viewItem" || key === "addToCart") &&
                  !context.server)
            }
          : null;
      }
    },
    {
      type: "CHECK_BOX",
      name: eventData.some(([_k, v]) => v.web !== null) ? "Web" : null,
      size:
        inputTextColumn1Name !== null || inputTextColumn2Name !== null
          ? {
              min: `${theme.gridBase * 7.5}px`,
              max: `${theme.gridBase * 12.5}px`
            }
          : {
              min: `${theme.gridBase * 6.5}px`,
              max: `${theme.gridBase * 15}px`
            },
      getIntersectTooltip: ({ key, context }) => {
        const text = getRestrictionTooltipText("web", key, context);
        return text ? { text } : null;
      },
      getAccessors: ({ key, context }, intersectTooltip) => {
        return context.web !== null
          ? {
              isChecked: context.web,
              setIsChecked: isChecked => {
                if (
                  isChecked &&
                  readCustomerShopifyScopeRequiredEventKeys.includes(key) &&
                  readCustomerShopifyScopeNotPresent
                ) {
                  globalErrorDispatch({
                    type: "SET_PROACTIVE",
                    payload: { websiteId, code: "SHOPIFY_LACKS_SCOPE" }
                  });
                } else {
                  details.setEventState({
                    ...details.eventState,
                    enabledWebEvents: {
                      ...details.eventState.enabledWebEvents,
                      [key]: isChecked
                    }
                  });
                  track.destinationEventsCheckBoxClick({
                    destinationName,
                    eventType: "WEB",
                    eventName: key,
                    eventEnabled: isChecked
                  });
                }
              },
              isDisabled:
                isLoading ||
                isBestPracticesSelected ||
                intersectTooltip?.text !== undefined
            }
          : null;
      }
    }
  ];

  return (
    <EventTableWrapper>
      {details.destinationName === "Google Ads" ? (
        <EventTableExplainer>
          We will auto-create conversion actions for selected event(s) and fill
          out the Conversion Action CTID for you. If you'd like to use existing
          conversion actions, just provide Conversion Action CTID manually.
        </EventTableExplainer>
      ) : details.destinationName === "Outbrain" ? (
        <EventTableExplainer>
          If you use multiple Outbrain accounts, be sure to use the same event
          names for both accounts.
        </EventTableExplainer>
      ) : null}
      <EventTable rows={rows} columns={columns} />
    </EventTableWrapper>
  );
};

const EventTableWrapper = styled.div`
  ${scrollbarHoriztonalMixin};
  overflow-x: auto;
  margin-bottom: ${props => props.theme.gridBase * -1}px;
  padding-bottom: ${props => props.theme.gridBase}px;
`;

const EventTableExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  max-width: ${props => props.theme.gridBase * 90}px;
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;
`;
